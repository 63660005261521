import { IonItem, IonAvatar } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import { AppContext } from "../../../AppStateProvider";
import { UserProfileSearch } from "../../../models/user-profile-search";

interface ProfileListProps {
    profileSearchResults: UserProfileSearch[];
    onClickPersonProfile: (user: any) => void;
    trickSearch: string
}

const VirtualProfileSearchList: React.FC<ProfileListProps> = ({
    profileSearchResults,
    onClickPersonProfile,
    trickSearch
}) => {
    const { state } = useContext(AppContext);
    const parentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState('100%');

    // Add resize observer to handle height changes
    useEffect(() => {
        const ionContent = parentRef.current?.closest('ion-content');
        if (!ionContent) return;

        const resizeObserver = new ResizeObserver(() => {
            setContentHeight(`${ionContent.offsetHeight}px`);
        });

        resizeObserver.observe(ionContent);

        // Initial height
        setContentHeight(`${ionContent.offsetHeight}px`);

        return () => resizeObserver.disconnect();
    }, []);


    console.log("Profile Search Results: " + profileSearchResults);

    const rowVirtualizer = useVirtualizer({
        count: profileSearchResults.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 100,
        overscan: 5,
    });

    if (profileSearchResults.length === 0) {
        return (
            <IonItem className="text-xl justify-center text-center">No users found searching
                for {trickSearch}</IonItem>
        )
    }

    return (
        <div
            ref={parentRef}
            className="h-full w-full overflow-auto"
            style={{
                position: 'relative',
                width: '100%',
                height: contentHeight,
                minHeight: '100vh'
            }}
        >
            <div
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                    const user = profileSearchResults[virtualRow.index];

                    return (
                        <div
                            key={virtualRow.key}
                            data-index={virtualRow.index}
                            ref={rowVirtualizer.measureElement}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${virtualRow.start}px)`,
                            }}
                        >
                            <IonItem
                                className="items-center"
                                onClick={() => onClickPersonProfile(user)}
                            >
                                <IonAvatar slot="start" className="ml-2">
                                    <img
                                        className="shrink w-8 h-8 rounded-full"
                                        src={user?.profile_pic ? user?.profile_pic : '/assets/photos/defaultProPic.png'}
                                        alt={`${user.username}'s profile`}
                                    />
                                </IonAvatar>
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <UsernameDisplay
                                            username={user?.username}
                                            className="text-xl"
                                            onUsernameClickHandler={() => console.log("Clicked on username")}
                                            userId={user?.id}
                                            loggedInId={state.user.id}
                                        />
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="text-base">{user?.name}</div>
                                    </div>
                                </div>
                            </IonItem>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default VirtualProfileSearchList;