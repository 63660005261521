import { IonApp, IonRouterOutlet, setupIonicReact, } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import React, { useEffect, useState } from "react";

/* Global CSS */
import "./global.css";
import { AppStateProvider } from "./AppStateProvider";

/* Tailwind styles */
import "./theme/tailwind.css";

/* Theme variables */
import "./theme/variables.css";
import { AppListenerProvider, useAppState } from "./AppListenerProvider";
import { AppAnalyticsProvider } from "./AppAnalyticsProvider";
import AppMain from "./AppMain";
import AppRedirect from "./components/ComponentsFunctional/AppRedirect/AppRedirect";
import { DeepLinkProvider } from "./DeepLinkProvider";
import { SignUpProvider, useSignUp } from "./components/ComponentsOnboarding/SignUpProvider/SignUpProvider";
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query'
import { VolumeProvider } from "./VolumeProvider";

const queryClient = new QueryClient()

const App: React.FC = () => {
    return (
        <IonReactRouter>
            <QueryClientProvider client={queryClient}>
                <AppStateProvider>
                    <AppListenerProvider>
                        <AppAnalyticsProvider>
                            <DeepLinkProvider>
                                <SignUpProvider>
                                    <VolumeProvider>
                                        <AppRedirect />
                                        <IonicApp />
                                    </VolumeProvider>
                                </SignUpProvider>
                            </DeepLinkProvider>
                        </AppAnalyticsProvider>
                    </AppListenerProvider>
                </AppStateProvider>
            </QueryClientProvider>
        </IonReactRouter>
    );
};


const IonicApp: React.FC = () => {
    // const { isDeterminingOnboarding } = useDetermineOnboarding();
    // const { isNative } = useAppState();

    // const handleSplashScreen = async () => {
    //     if (isDeterminingOnboarding) {
    //         console.log("SHOWING SPLASH SCREEN");
    //         await SplashScreen.show({ autoHide: false });
    //     } else {
    //         console.log("HIDING SPLASH SCREEN");
    //         await SplashScreen.hide();
    //     }
    // };

    // // Use splash screen for native, other wise just default to our loading screen -> WE MADE OUR OWN SPLASH SCREEN! - I COULD NEVER FIGURE THIS OUT!
    // useEffect(() => {
    //     if (isNative) {
    //         handleSplashScreen();
    //     }
    // }, [isDeterminingOnboarding, isNative]);

    return (
        <IonApp>
            <AppMain />
        </IonApp>
    );
};

export default App;
