import React, { useEffect, useState } from "react";
import { useParams, useHistory, RouteComponentProps, useLocation } from "react-router";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    IonButton, IonContent, IonHeader, IonImg, IonPage, IonRefresher, IonRefresherContent,
    IonTitle, IonToolbar, RefresherEventDetail, useIonAlert, useIonModal
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { format, parseISO } from 'date-fns';
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";

import BrandDescriptionModal from "../components/ComponentsCrewProfile/BrandDescriptionModal/BrandDescriptionModal";
import EditDeleteEvent from "../components/ComponentsEvent/EditDeleteEvent/EditDeleteEvent";
import ToolbarSearchBar from "../components/ComponentsUI/ToolbarSearchBar/ToolbarSearchBar";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import BrandEventInfoCard from "../components/ComponentsCrewProfile/BrandEventInfoCard/BrandEventInfoCard";
import IDXChip from "../components/ComponentsUI/IDXChip/IDXChip";
import EventShare from "../components/ComponentsShare/EventShare/EventShare";
import IDXButtonIcon from "../components/ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import EventPageLoading from "../components/ComponentsUI/EventPageLoading/EventPageLoading";

import { BrandEvent } from "../models/brandEvent";
import { UserTrickSearch } from "../models/user-trick-search";
import BrandsService from "../services/brand.service";
import { handleTagClickUtil } from "../services/utils";
import { useAuth } from "../AuthProvider";
import { useAppState } from "../AppListenerProvider";
import ProfileVirtualGrid from "../components/ComponentsProfile/ProfileVirtualGrid/ProfileVirtualGrid";
import EclipticSearchbar from "../components/ComponentsUI/EclipticSearchbar/EclipticSearchbar";

interface EventParams {
    id?: string;
}

interface EventPageProps extends RouteComponentProps<EventParams> { }

const BrandEventInfo: React.FC<EventPageProps> = ({ match }) => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { user, isAuthenticated } = useAuth();
    const { isNative } = useAppState();

    const [eventTricksFilter, setEventTricksFilter] = useState("");
    const [activeTab, setActiveTab] = useState('Event Bag'); // Remove the array destructuring and add setter
    const [presentAlert] = useIonAlert();

    const { data: brandEvent, isLoading: isEventLoading, isError: isEventError } = useQuery({
        queryKey: ['brandEvent', id],
        queryFn: () => BrandsService.getEventById(id),
    });

    const { data: brand, isLoading: isBrandLoading } = useQuery({
        queryKey: ['brand', brandEvent?.brand_id],
        queryFn: () => BrandsService.getBrandById(brandEvent.brand_id),
        enabled: !!brandEvent,
    });

    const { data: eventTricks, isLoading: isEventTricksLoading } = useQuery({
        queryKey: ['eventTricks', id],
        queryFn: () => BrandsService.getEventTricks(
            id,
            brandEvent.start_date,
            brandEvent.end_date,
            brandEvent.event_tag,
            brandEvent.sports,
            brandEvent.features,
            brandEvent.event_trick_tags,
            brandEvent.event_tricks
        ),
        enabled: !!brandEvent,
    });

    const isEditBrand = isAuthenticated && brand?.auth_id === user.uid;

    const [presentEventDescriptionModal, dismissEventDescriptionModal] = useIonModal(BrandDescriptionModal, {
        onDismiss: (data: string, role: string) => dismissEventDescriptionModal(data, role),
        description: brandEvent?.description,
        title: brandEvent?.name
    });

    const handleTagClick = (tag: string) => {
        const newTag = handleTagClickUtil(tag);
        history.push('/search/' + newTag);
    };

    const onRefreshBrandEvent = async (event: CustomEvent<RefresherEventDetail>) => {
        await queryClient.invalidateQueries({ queryKey: ['brandEvent', id] });
        event.detail.complete();
    };

    const goToUploadTrick = (brandEvent: BrandEvent) => {
        if (isEditBrand) {
            presentAlert({
                header: `Error`,
                message: `You cannot upload a trick if you are a brand`,
                buttons: [{ text: 'Exit', role: 'cancel' }],
            });
        } else {
            history.push(`/upload?tag=${brandEvent.event_tag.replace("#", '')}`);
        }
    };

    const onDismissEditEvent = async (data: string, role: string) => {
        if (role === 'success') {
            await queryClient.invalidateQueries({ queryKey: ['brandEvent', id] });
        }
    };

    // Delete event mutation
    const deleteEventMutation = useMutation({
        mutationFn: (event: BrandEvent) => BrandsService.deleteBrandEvent(brand.id, event.id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['brandEvents', brand?.id] });
        }
    });

    const onClickTrick = (trick: UserTrickSearch) => {
        history.push('/clip/' + trick.user_id + '/' + trick.trick_id);
        return;
    }

    const getColumnCountEvents = () => {
        const width = window.innerWidth;
        if (width >= 1280) return 6;
        if (width >= 768) return 4;
        if (width >= 640) return 3;
        return 2;
    };

    useEffect(() => {
        if (!isEventLoading && !isBrandLoading && !isEventTricksLoading) {
            window.prerenderReady = true;
        }
    }, [isEventLoading, isBrandLoading, isEventTricksLoading]);

    if (isEventLoading || isBrandLoading || isEventTricksLoading) {
        return (
            <IonPage>
                <CustomHelmet
                    title={brand?.username && brandEvent?.name ? `Ecliptic // ${brand.username} // ${brandEvent.name}` : "idx // View Event"}
                    description={brandEvent?.description || "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                    image={brandEvent?.cover_pic || "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/event/${id}`}
                />
                <ToolbarSearchBar />
                <IonContent>
                    <EventPageLoading />
                </IonContent>
            </IonPage>
        );
    }

    if (isEventError) {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Event</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div className="flex flex-row justify-center items-center w-full mt-8">
                        <IonButton color="favorite" onClick={() => history.push('/community')}>
                            Event not found, return to the community page
                        </IonButton>
                    </div>
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonPage>
            <CustomHelmet
                title={brand?.username && brandEvent?.name ? `Ecliptic // ${brand.username} // ${brandEvent.name}` : "idx // View Event"}
                description={brandEvent?.description || "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                image={brandEvent?.cover_pic || "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/event/${id}`}
            />
            <ToolbarSearchBar />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={onRefreshBrandEvent}>
                    <IonRefresherContent />
                </IonRefresher>
                <div>
                    <div className="flex flex-row justify-between mx-2">
                        <div>
                            <IonButton fill="default" size="small" onClick={
                                () => {
                                    if (location.key) {
                                        history.go(-1);
                                    } else {
                                        history.push('/community')
                                    }
                                }}>
                                <IDXButtonIcon size="text-xl" icon={arrowBack} />
                            </IonButton>
                        </div>
                        <EventShare isCapacitor={isNative} event={brandEvent} />
                    </div>
                    <div className="flex flex-row">
                        <div className="object-contain w-full grow">
                            <IonImg src={brandEvent?.cover_pic} alt={brandEvent?.name} />
                        </div>
                    </div>
                    <div className="flex flex-row mx-3 my-2 justify-between">
                        <div className="flex flex-col gap-y-1 items-start basis-3/4">
                            <div className="text-xl mx-1 font-bold">
                                {brandEvent?.name}
                            </div>
                            <div className="text-lg mx-1 font-bold" onClick={() => history.push(`/crews/${brand.username}`)}>
                                {brand?.name}
                            </div>
                            <div className="flex flex-row gap-2 text-lg mx-1 items-center">
                                {brandEvent?.start_date && brandEvent?.end_date ?
                                    `${format(parseISO(brandEvent?.start_date), 'MMM d, yyyy')} - ${format(parseISO(brandEvent?.end_date), 'MMM d, yyyy')}`
                                    : null}
                            </div>
                            <div className="text-xl flex flex-row gap-x-2">
                                {(brandEvent?.start_date && brandEvent?.end_date) &&
                                    (new Date(new Date().toDateString()).toISOString() >= brandEvent?.start_date &&
                                        new Date(new Date().toDateString()).toISOString() <= brandEvent?.end_date ?
                                        <IDXChip
                                            bgColor="bg-highlight-secondary/50 hover:bg-highlight-secondary/95 click:bg-highlight-secondary/95"
                                            borderColor="border-2 border-highlight-secondary"
                                            text="Live"
                                        /> :
                                        <IDXChip
                                            bgColor="bg-highlight-danger/50 hover:bg-highlight-danger/95 click:bg-highlight-danger/95"
                                            borderColor="border-2 border-highlight-danger"
                                            text="Closed"
                                        />
                                    )
                                }
                                {brandEvent?.location &&
                                    <IDXChip
                                        bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                        borderColor="border-2 border-highlight"
                                        text={brandEvent?.location}
                                    />
                                }
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-1 items-end basis-1/4">
                            <IonButton fill="solid" color="favorite" size="small"
                                onClick={() => goToUploadTrick(brandEvent)}>
                                Upload
                            </IonButton>
                            <IonButton fill="solid" color="favorite-secondary" size="small"
                                onClick={() => presentEventDescriptionModal()}>
                                Rules
                            </IonButton>
                            <div className="flex flex-row-reverse gap-x-2">
                                {isEditBrand &&
                                    <EditDeleteEvent
                                        onDismissEditEvent={onDismissEditEvent}
                                        deleteEvent={deleteEventMutation.mutate}
                                        brandEvent={brandEvent}
                                        brand={brand}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mx-3 my-1">
                        <div className="flex flex-wrap gap-x-2 gap-y-2">
                            {brandEvent?.event_tag &&
                                <IDXChip
                                    bgColor="bg-transparent hover:bg-slate-500/50 click:bg-slate-500/50"
                                    borderColor="border-2 border-primary-secondary"
                                    text={brandEvent?.event_tag}
                                />
                            }
                            {brandEvent?.sports?.map((tag: any, index: number) => (
                                <IDXChip
                                    key={index}
                                    bgColor="hover:bg-highlight-alt/95 click:bg-highlight-alt/95"
                                    borderColor="border-2 border-highlight-alt"
                                    text={tag}
                                    onClickChip={(tag) => handleTagClick(tag)}
                                />
                            ))}
                            {brandEvent?.features?.map((tag: any, index: number) => (
                                <IDXChip
                                    key={index}
                                    bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight"
                                    onClickChip={(tag) => handleTagClick(tag)}
                                    text={tag}
                                />
                            ))}
                            {brandEvent?.event_trick_tags?.map((tag: any, index: number) => (
                                <IDXChip
                                    text={tag}
                                    key={index}
                                    bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight"
                                    onClickChip={(tag) => handleTagClick(tag)}
                                />
                            ))}
                            {brandEvent?.event_tricks?.map((trick: string[], index: number) => (
                                <IDXChip
                                    key={index}
                                    text={trick.join(" ")}
                                    bgColor="hover:bg-highlight/95 click:bg-highlight/95"
                                    borderColor="border-2 border-highlight"
                                    onClickChip={(tag) => handleTagClick(tag)}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="flex justify-center space-x-4 border-b-2 border-gray-500 text-base font-bold">
                        <div className={activeTab === 'Event Bag' ? "border-b-4 border-[#5963A7]" : ""}>
                            <button
                                className={`py-2 px-2.5 text-xl ${activeTab === 'Event Bag' ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-400'}`}
                                onClick={() => setActiveTab('Event Bag')}>
                                Event Bag <span className="text-sm">{eventTricks?.length || 0}</span>
                            </button>
                        </div>
                        <div className={activeTab === 'Leaderboards' ? "border-b-4 border-[#5963A7]" : ""}>
                            <button
                                className={`py-2 px-2.5 text-xl ${activeTab === 'Leaderboards' ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-400'}`}
                                onClick={() => setActiveTab('Leaderboards')}>
                                Leaderboards
                            </button>
                        </div>
                    </div>
                    <div className="p-1">
                        {
                            activeTab === "Event Bag" &&
                            <>
                                <div className="flex flex-row justify-between items-center mb-0.5">
                                    <EclipticSearchbar onIonInput={(e) => setEventTricksFilter(e.detail.value)}
                                        showClearButton="focus"
                                        showCancelButton="focus"
                                        style={{ paddingTop: 0, paddingBottom: 0 }}
                                        id="searchText"
                                        inputmode="search"
                                        debounce={0}
                                        animated={true}></EclipticSearchbar>
                                </div>
                                <ProfileVirtualGrid<UserTrickSearch>
                                    items={eventTricks}
                                    averageHeight={1000}
                                    renderItem={
                                        (trick) =>
                                            <div>
                                                <BrandEventInfoCard trick={trick}></BrandEventInfoCard>
                                            </div>
                                    }
                                    getItemId={(trick) => trick.id}
                                    filter={(trick: UserTrickSearch) =>
                                        trick.trick_name.toLowerCase().includes(eventTricksFilter.toLowerCase()) ||
                                        (trick.location && trick.location.toLowerCase().includes(eventTricksFilter.toLowerCase()))
                                        || trick.username && trick.username.toLowerCase().includes(eventTricksFilter.toLowerCase())
                                    }
                                    onClick={onClickTrick}
                                    activeTab={activeTab}
                                    getColumnCount={getColumnCountEvents}
                                />
                            </>
                        }
                        {activeTab === "Leaderboards" && (
                            <div className="flex flex-col items-center justify-center p-4">
                                <p className="text-white">Leaderboards coming soon!</p>
                            </div>
                        )}
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default BrandEventInfo;