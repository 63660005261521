import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PhoneNumber } from 'libphonenumber-js';
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";
import AuthService from "../../../services/auth.service";
import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import OTPInput from "../../ComponentsUI/OTPInput/OTPInput";
import PhoneInput from "../../ComponentsUI/PhoneInput/PhoneInput";
import UserService from '../../../services/user.service';
import { processUserData } from '../../../services/utils';
import WelcomeHelp from '../WelcomeHelp/WelcomeHelp';

interface PhoneInputProps {
    onNext: () => void;
    onBack: () => void;
    onSkip?: () => void;
    onSkipDelay?: number;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    signUp: boolean;
}

const PhoneNumberInput: React.FC<PhoneInputProps> = ({
    onNext,
    showSkipButton,
    showBackButton,
    title,
    onBack,
    onSkip,
    onSkipDelay,
    signUp
}) => {
    const { signUpData, updateSignUpData } = useSignUp();
    const { isNative, platform, isActive } = useAppState();
    const { auth, user, isLoading, isAuthenticated, firebaseApp } = useAuth();
    const authService = AuthService({ auth, user, isLoading, isAuthenticated, firebaseApp }, {
        isActive,
        isNative,
        platform
    });

    const [phoneNumberObj, setPhoneNumberObj] = useState<PhoneNumber | null>(null);
    const [error, setError] = useState<string | null>(null);
    const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
    const recaptchaContainerRef = useRef<HTMLDivElement | null>(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationId, setVerificationId] = useState('');
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
    const [isVerificationView, setIsVerificationView] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submittedRef = useRef(false);

    const clearRecaptcha = useCallback(() => {
        if (recaptchaVerifierRef.current) {
            recaptchaVerifierRef.current.clear();
            recaptchaVerifierRef.current = null;
        }
        if (recaptchaContainerRef.current) {
            recaptchaContainerRef.current.innerHTML = '';
        }
    }, []);

    const initializeRecaptcha = useCallback(() => {
        if (!recaptchaContainerRef.current || !auth) {
            console.error('Recaptcha container ref or Firebase Auth is not available');
            return;
        }

        clearRecaptcha();

        try {
            recaptchaVerifierRef.current = new RecaptchaVerifier(auth, recaptchaContainerRef.current, {
                size: 'invisible'
            });
            console.log('Recaptcha initialized successfully');
        } catch (error) {
            console.error('Error initializing Recaptcha:', error);
        }
    }, [auth, clearRecaptcha]);

    useEffect(() => {
        if (!isNative && !isVerificationView) {
            initializeRecaptcha();
        }
        return () => {
            if (!isNative) {
                clearRecaptcha();
            }
        };
    }, [isNative, isVerificationView, initializeRecaptcha, clearRecaptcha]);

    const onBackPhoneVerifyHandler = useCallback(() => {
        setIsVerificationView(false);
        if (!isNative) {
            clearRecaptcha();
            initializeRecaptcha();
        }
    }, [isNative, clearRecaptcha, initializeRecaptcha]);

    const handlePhoneSubmit = async (phoneNumberObj: PhoneNumber) => {
        setPhoneNumberObj(phoneNumberObj);
        setError(null);

        try {
            console.log('Starting phone verification for:', phoneNumberObj.number);

            const result = await authService.signInWithPhoneNumberHandler(
                phoneNumberObj.number,
                !isNative ? recaptchaVerifierRef.current : undefined
            );

            console.log('Phone verification result:', result);

            if (result.verificationCompleted) {
                // Automatic verification completed (rare on iOS)
                if (signUp) {
                    await updateSignUpData({
                        phoneNumber: phoneNumberObj,
                        userId: result.user.uid
                    });
                }
                onNext();
            } else if (result.confirmationResult?.verificationId) {
                // Web flow - verification ID is inside confirmationResult
                console.log("Showing verification code input screen");
                setVerificationId(result.confirmationResult.verificationId);
                setConfirmationResult(result.confirmationResult);
                setIsVerificationView(true);
            } else if (result.verificationId) {
                // Native flow - verification ID is directly on result
                console.log("Showing verification code input screen");
                setVerificationId(result.verificationId);
                setIsVerificationView(true);
            }
        } catch (error) {
            console.error('Error in phone verification:', error);
            setError('Failed to verify phone number. Please skip if the issue persists.');
        }
    };

    const handleVerificationSubmit = async (otp: string) => {
        setVerificationCode(otp);

        if (!otp) {
            setError('Please enter the verification code');
            return;
        }

        // Prevent multiple submissions
        if (isSubmitting || submittedRef.current) {
            console.log('Submission already in progress or completed');
            return;
        }

        try {
            setIsSubmitting(true);

            const user = await authService.confirmPhoneVerificationCode(
                otp,
                verificationId,
                !isNative ? confirmationResult : undefined
            );

            console.log("Phone verification completed in handleVerificationSubmit: ", user);

            if (signUp && !submittedRef.current) {
                // Set the ref immediately to prevent race conditions
                submittedRef.current = true;

                try {
                    // Create User with transaction ID
                    const user_id = await UserService.createUserOnboardingPhone(
                        user.uid,
                        phoneNumberObj.number,
                        signUpData.name,
                        signUpData.username,
                    );

                    const processed = processUserData(signUpData);

                    await UserService.upsertUserProfileInformationOnboarding(
                        user_id,
                        processed.name,
                        processed.username,
                        processed.birthday,
                        processed.sports,
                        processed.level,
                        processed.uniqueTerrain,
                        processed.location_id,
                        processed.location_name,
                    );

                    await updateSignUpData({
                        phoneNumber: phoneNumberObj,
                        userId: user.uid,
                    });

                    onNext();
                } catch (error) {
                    console.error('Error in user creation:', error);
                    submittedRef.current = false; // Reset on error
                    throw error; // Re-throw to be caught by outer catch
                }
            } else {
                onNext();
            }
        } catch (error) {
            console.error('Error confirming verification code:', error);
            setError('Invalid verification code. Please try again or skip if the issue persists.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <OnboardingPageWrapper
            title={isVerificationView ? 'Verify your number' : title}
            showBackButton={showBackButton}
            showSkipButton={signUp ? showSkipButton : false}
            onBack={(isVerificationView) ? onBackPhoneVerifyHandler : onBack}
            onSkip={onSkip}
            onSkipDelay={onSkipDelay}
        >
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                {isVerificationView ? (
                    <OTPInput
                        length={6}
                        onComplete={handleVerificationSubmit}
                        buttonClass={signUp ? 'neon-button-alt' : 'neon-button'}
                        inputClass={"neon-text"}
                        signUp={signUp}
                        clearError={() => setError(null)}
                        isNative={isNative}
                    />
                ) : (
                    <PhoneInput
                        signUp={signUp}
                        onSubmit={handlePhoneSubmit}
                        isActive={isActive}
                        isNative={isNative}
                        countryData={signUpData.countryData}
                        location={signUpData.location}
                        sizeClass={"text-2xl"}
                    />
                )}
                <WelcomeHelp />
                {error && <div className="error text-highlight-danger text-center">{error}</div>}
                <div ref={recaptchaContainerRef} id="sign-in-button"></div>
            </div>
        </OnboardingPageWrapper>
    );
};

export default PhoneNumberInput;