import React, { useEffect, useState } from 'react';
import {
    IonPage,
    IonContent,
    IonButton,
    IonIcon,
    useIonToast,
    IonHeader,
    IonToolbar,
} from '@ionic/react';
import {
    shareOutline,
    personOutline,
    logoInstagram,
    cloudUploadOutline,
    linkOutline,
    alertCircleOutline,
} from 'ionicons/icons';
import { Share } from '@capacitor/share';
import biskSvg from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import { AppContext } from '../../../AppStateProvider';
import { Clipboard } from "@capacitor/clipboard";
import AutoVideoPlayer from '../../ComponentsVideo/AutoVideoPlayer/AutoVideoPlayer';
import { useInstagramShare } from '../../../hooks/useInstagramShare';
import UserService from '../../../services/user.service';
import { BiskTransaction } from '../../../models/biskTransaction';
import BiskService from '../../../services/bisk.service';
import { UserBisk } from '../../../models/userBisk';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RouteComponentProps, useHistory } from 'react-router';
import { useAppState } from '../../../AppListenerProvider';
import TricksService from '../../../services/tricks.service';
import HomePageVideoPlayer from '../../ComponentsVideo/HomePageVideo/HomePageVideo';
import TrickVideoLoading from '../../ComponentsUI/TrickVideoLoading/TrickVideoLoading';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

const customEmojis = ["👏", "🎉", "🎈", "🫡", "🎊", "😎", "😮‍💨", "😤"]

interface UploadSuccessParams {
    user_id?: string;
    id?: string;
}

interface UploadSuccessProps extends RouteComponentProps<UploadSuccessParams> {
}

const UploadSuccess: React.FC<UploadSuccessProps> = ({ match }) => {
    const { state, dispatch } = React.useContext(AppContext);
    const { user_id, id } = match.params;
    const history = useHistory();
    const { isNative, platform } = useAppState();

    const { instagramInstalled, loading, checkInstagram } = useInstagramShare();

    const [present] = useIonToast();
    const [claimBisk, setClaimBisk] = useState<boolean>(false);
    const [biskDisabled, setBiskDisabled] = useState<boolean>(false);
    const [biskReward, setBiskReward] = useState<number>(0);
    const [biskMessage, setBiskMessage] = useState<string>('');

    const queryClient = useQueryClient();

    const { data: trick, isLoading: isTrickLoading } = useQuery({
        queryKey: ['userTrick', id, user_id],
        queryFn: () => TricksService.getUserTrick(id, user_id),
    });

    const { data: videoProperties, isLoading: isLoadingVideoProperties } = useQuery({
        queryKey: ['videoProperties', id],
        queryFn: () => TricksService.getTrickVideoProperties(id),
        enabled: !!trick,
    });

    const getBiskReward = async () => {
        const biskRewardObject = await UserService.getBiskRewardForUpload(user_id);
        if (biskRewardObject) {
            console.log("Bisk Reward Object: ", biskRewardObject);
            const bisk = biskRewardObject['reward'];
            const message = biskRewardObject['message'];
            if (bisk > 0) {
                setClaimBisk(true);
                setBiskReward(bisk);
                setBiskMessage(message);
            } else {
                setClaimBisk(false);
            }
        } else {
            setClaimBisk(false);
        }
    }

    useEffect(() => {
        getBiskReward();
    }, [user_id]);

    const invalidateQueries = async () => {
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['userProfile', state.user.username] }),
            queryClient.invalidateQueries({ queryKey: ['userTricks', state.user.id] }),
            queryClient.invalidateQueries({ queryKey: ['newTricks'] }),
        ]);
    }

    const handleGoToProfile = async () => {
        // Use router.push with a `replace` strategy to avoid the tab replacement issue.
        await Haptics.impact({ style: ImpactStyle.Medium });
        await invalidateQueries();
        history.push(`/profile/${state.user.username}`);
    }

    const uploadMore = async () => {
        await Haptics.impact({ style: ImpactStyle.Medium });
        await invalidateQueries();
        history.push('/upload');
    }

    const handleShare = async () => {
        await Haptics.impact({ style: ImpactStyle.Light });
        try {
            await Share.share({
                title: trick.trick_name,
                text: `${trick.trick_name} // ${state.user?.username} // Ecliptic!`,
                url: `https://ecliptic.day/clip/${user_id}/${id}`,
                dialogTitle: 'Share your trick',
            });
        } catch (error) {
            console.error('Error sharing', error);
        }
    };

    const handleInstagramShare = async () => {
        await Haptics.impact({ style: ImpactStyle.Light });
        checkInstagram({
            backgroundVideoURL: trick.video,
            stickerImageURL: 'https://corked.blob.core.windows.net/logos/eclipticigstickershare.png'
        });
    };

    const handleCopyLink = async () => {
        await Clipboard.write({
            string: `https://ecliptic.day/clip/${user_id}/${id}`
        });

        present({
            message: 'Link copied to clipboard!',
            duration: 3000,
            position: 'top',
            color: 'success',

        });
    }

    const getBisk = async (profileId: string) => {
        try {
            const bisk: UserBisk = await BiskService.getBiskForUser(profileId);
            return bisk.bisk;
        } catch (e) {
            console.log("Error getting Bisk: ", e);
            return state.bisk;
        }
    }

    const onRedeem = async (biskSend: number, note: string) => {
        try {
            const biskRewardTransaction: BiskTransaction = await UserService.postBiskRewardForUpload(user_id, biskSend, note);
            console.log("Result: ", biskRewardTransaction);
            await Haptics.impact({ style: ImpactStyle.Medium });
            await present({
                message: `Congrats on the ${biskRewardTransaction.amount} Bisk!`,
                duration: 5000, // Duration in milliseconds
                icon: biskSvg,
                position: "top",
                color: "success",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
            const recieverBisk = await getBisk(user_id);
            dispatch({ type: "setBisk", payload: recieverBisk })
        } catch (e) {
            console.log("Error sending Bisk: ", e);
            await present({
                message: `Failed to redeem Bisk: Error: ${e}`,
                duration: 5000, // Duration in milliseconds
                icon: alertCircleOutline,
                position: "top",
                color: "danger",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
        } finally {
            setBiskDisabled(true);
        }
    }

    const handleClaimBisk = async () => {
        await onRedeem(biskReward, biskMessage);
    };

    const randomEmoji = customEmojis[Math.floor(Math.random() * customEmojis.length)];

    // TODO: On Mobile - Claim Still Off - Not Disabled - Fix
    // Clicking on View Trick Messes Up Routing When Go Back
    // Need to Test Go To Profile Button

    if (isTrickLoading || isLoadingVideoProperties) {
        return (
            <IonPage>
                <IonHeader translucent={true}>
                    {/* Add a toolbar to maintain consistent height */}
                    <IonToolbar>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <TrickVideoLoading />
                </IonContent>
            </IonPage>
        );
    }

    return (
        <IonPage>
            <IonHeader translucent={true}>
            </IonHeader>
            <IonContent>
                <div className="flex flex-col items-center p-4 mt-8">
                    <h1 className="text-2xl font-bold my-6">{trick.trick_name} Uploaded {randomEmoji}</h1>

                    {/* Video Preview */}
                    <div className="w-full max-w-md mx-auto max-h-md"> {/* Added container */}
                        <HomePageVideoPlayer
                            isCapacitor={isNative}
                            src={trick.video}
                            thumbnail={trick.thumbnail}
                            id={trick.trick_id}
                            isCached={false}
                            isHorizontal={!videoProperties.is_vertical}
                            isExternal={videoProperties.is_external}
                            aspectRatio={videoProperties.aspect_ratio}
                            isAirtime={videoProperties.is_airtime}
                            isVimeo={videoProperties.is_vimeo}
                            isYoutube={videoProperties.is_youtube}
                            isScrolling={false}
                            scrollSpeed={0}
                        />
                    </div>

                    {/* Action Buttons */}
                    <div className="grid grid-cols-2 gap-4 w-full max-w-2xl my-4">
                        <IonButton
                            className="neon-button-alt"
                            onClick={async () => await handleShare()}
                        >
                            <IonIcon slot="start" icon={shareOutline} />
                            Share
                        </IonButton>

                        {
                            isNative ? (
                                <IonButton
                                    className="neon-button-alt"
                                    onClick={async () => await handleInstagramShare()}
                                    disabled={videoProperties.is_external || platform === 'android'}
                                >
                                    <IonIcon slot="start" icon={logoInstagram} />
                                    Instagram
                                </IonButton>
                            ) : (
                                <IonButton
                                    className="neon-button-alt"
                                    onClick={async () => await handleCopyLink()}
                                >
                                    <IonIcon slot="start" icon={linkOutline} />
                                    Copy Link
                                </IonButton>
                            )
                        }

                        <IonButton
                            className="neon-button col-span-2"
                            onClick={async () => await handleGoToProfile()}
                        >
                            <IonIcon slot="start" icon={personOutline} />
                            View Clip/Go to Profile
                        </IonButton>
                        <IonButton
                            className="neon-button-highlight"
                            disabled={!claimBisk || biskDisabled}
                            onClick={async () => await handleClaimBisk()}
                        >
                            <IonIcon slot="start" color="dark" icon={biskSvg} />
                            <span>Claim Bisk</span>
                        </IonButton>
                        <IonButton
                            className={"neon-button-highlight"}
                            onClick={async () => uploadMore()}
                        >
                            <IonIcon slot="start" icon={cloudUploadOutline} />
                            Upload More
                        </IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default UploadSuccess;