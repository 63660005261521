import { IonCard, IonImg } from "@ionic/react";
import React, { useEffect, useState, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { UserTrickSearch } from "../../../models/user-trick-search";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import { profile } from "console";

interface ContainerProps {
    trickArray: UserTrickSearch[];
    onTrickSelection: (trick: UserTrickSearch) => void;
    loggedInId?: string;
    trickSearch: string;
}

const TrickSearchCardHolder: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [columnCount, setColumnCount] = useState(4);
    const parentRef = useRef<HTMLDivElement>(null);

    const [contentHeight, setContentHeight] = useState('100%');

    // Add resize observer to handle height changes
    useEffect(() => {
        const ionContent = parentRef.current?.closest('ion-content');
        if (!ionContent) return;

        const resizeObserver = new ResizeObserver(() => {
            setContentHeight(`${ionContent.offsetHeight}px`);
        });

        resizeObserver.observe(ionContent);

        // Initial height
        setContentHeight(`${ionContent.offsetHeight}px`);

        return () => resizeObserver.disconnect();
    }, []);

    // Handle responsive column count
    useEffect(() => {
        const updateColumnCount = () => {
            const width = window.innerWidth;
            if (width >= 1280) setColumnCount(6);      // xl:grid-cols-6
            else if (width >= 768) setColumnCount(4);  // md:grid-cols-4
            else if (width >= 640) setColumnCount(3);  // sm:grid-cols-3
            else if (width >= 480) setColumnCount(2);  // xs:grid-cols-2
            else setColumnCount(2);                    // default
        };

        updateColumnCount();
        window.addEventListener('resize', updateColumnCount);
        return () => window.removeEventListener('resize', updateColumnCount);
    }, []);


    const rowVirtualizer = useVirtualizer({
        count: Math.ceil(props.trickArray.length / columnCount),
        getScrollElement: () => parentRef.current,
        estimateSize: () => 600, // Adjust based on your card height
        overscan: 2,
    });

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columnCount,
        getScrollElement: () => parentRef.current,
        estimateSize: () => parentRef.current ? parentRef.current.offsetWidth / columnCount : 300,
        overscan: 2,
    });

    return (
        <div
            ref={parentRef}
            className="h-full w-full overflow-auto"
            style={{
                position: 'relative',
                width: '100%',
                height: contentHeight,
                minHeight: '100vh'
            }}
        >
            <div
                style={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                }}
            >
                {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                    <div
                        key={virtualRow.key}
                        data-index={virtualRow.index}
                        ref={rowVirtualizer.measureElement}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${virtualRow.start}px)`,
                            display: 'flex',
                            gap: '0.5rem',     // gap-2
                            padding: '0.25rem' // p-1
                        }}
                    >
                        {columnVirtualizer.getVirtualItems().map((virtualColumn) => {
                            const trickIndex = virtualRow.index * columnCount + virtualColumn.index;
                            const trick = props.trickArray[trickIndex];
                            if (!trick) return null;

                            return (
                                <div
                                    key={virtualColumn.key}
                                    style={{
                                        width: `${100 / columnCount}%`,
                                        padding: '8px',
                                    }}
                                    onClick={() => props.onTrickSelection(trick)}
                                >
                                    <IonCard className="cursor-pointer p-0 m-0 border border-gray-900 bg-black font-bold">
                                        <IonImg
                                            className="object-cover min-w-full min-h-full h-full aspect-video"
                                            src={trick?.thumbnail}
                                        />
                                        <div className="bg-black p-4">
                                            <div className="text-base text-white">{trick.trick_name}</div>
                                            <div className="text-sm">
                                                <UsernameDisplay
                                                    username={trick.username}
                                                    className=""
                                                    loggedInId={props.loggedInId}
                                                    userId={trick.user_id}
                                                />
                                            </div>
                                        </div>
                                    </IonCard>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TrickSearchCardHolder;