import { useInfiniteQuery } from "@tanstack/react-query";
import SearchService from "../services/search.service";
import { useContext } from "react";
import { AppContext } from "../AppStateProvider";
import { useAppState } from "../AppListenerProvider";

// useProfileSearch.ts
export const useProfileSearch = (searchTerm: string) => {
    const { state } = useContext(AppContext);
    const { isNative } = useAppState();
    const PAGE_SIZE = isNative ? 12 : 20;

    return useInfiniteQuery({
        queryKey: ['profileSearch', searchTerm, state.user.id],
        queryFn: async ({ pageParam = 0 }) => {
            try {
                const data = await SearchService.searchUserProfiles(
                    searchTerm ?? '', // Ensure searchTerm is never undefined
                    PAGE_SIZE,
                    pageParam,
                    state.user.id
                );
                return {
                    profiles: data || [], // Ensure data is never undefined
                    nextPage: data?.length === PAGE_SIZE ? pageParam + 1 : undefined,
                    prevPage: pageParam
                };
            } catch (error) {
                console.error('Search error:', error);
                return { profiles: [], nextPage: undefined, prevPage: pageParam };
            }
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage) => lastPage.nextPage,
        enabled: Boolean(searchTerm) && Boolean(state?.user?.id), // Safe check
        refetchOnMount: true,      // Refetch when component remounts
    });
};