import React, { useEffect, useState } from 'react';
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";
import AuthenticationButtons from "../AuthenticationButtons/AuthenticationButtons";
import { useAppState } from "../../../AppListenerProvider";
import { useAuth } from "../../../AuthProvider";
import { useDeepLink } from "../../../DeepLinkProvider";
import AuthService from "../../../services/auth.service";
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import WelcomeHelp from '../WelcomeHelp/WelcomeHelp';

interface WelcomeSignUpAuthProps {
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    handleAuthedStep: () => void;
    handleEmailAuthStep: () => void;
}

const WelcomeSignUpAuth: React.FC<WelcomeSignUpAuthProps> = ({
    showSkipButton,
    showBackButton,
    title,
    handleAuthedStep,
    handleEmailAuthStep
}) => {
    const { isActive, isNative, platform } = useAppState();
    const { user, isLoading, isAuthenticated, firebaseApp, auth } = useAuth();
    const authService = AuthService({ user, isLoading, isAuthenticated, firebaseApp, auth }, {
        isActive,
        isNative,
        platform
    });

    const { deepLinkData, clearDeepLinkData } = useDeepLink();
    const [referral, setReferral] = useState<string | null>(null);


    useEffect(() => {
        if (deepLinkData.slug) {
            console.log("Handling deep link in onboarding:", deepLinkData.slug);
            if (deepLinkData.slug.startsWith('/share') && deepLinkData.referrer) {
                setReferral(deepLinkData.referrer);
                console.log(`Referral from: ${deepLinkData.referrer}`);
            }
        }
    }, [deepLinkData]);

    return (
        <OnboardingPageWrapper title={title} showBackButton={showBackButton} showSkipButton={showSkipButton}>
            <AuthenticationButtons
                login={false}
                authService={authService}
                platform={platform}
                handleAuthedStep={handleAuthedStep}
                handleEmailAuthStep={handleEmailAuthStep}
            />
            <WelcomeHelp />
            {referral && (
                <div className="text-sm text-primary-alt text-center">
                    Referred by: <span className="text-electric-blue">{referral}</span>
                </div>
            )}
        </OnboardingPageWrapper>
    );
};

export default WelcomeSignUpAuth;