import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { IonContent, IonSelect, IonSelectOption, IonRefresher, IonRefresherContent } from '@ionic/react';
import { useAuth } from '../../../AuthProvider';
import { useBoardResults } from '../../../hooks/useBoardResults';
import { BoardType } from '../../../models/boardType';
import ShopRedirect from '../../ComponentsPurchases/ShopRedirect/ShopRedirect';
import BoardLoading from '../../ComponentsUI/BoardLoading/BoardLoading';
import BoardIDXInfoCard from '../BoardIDXInfoCard/BoardIDXInfoCard';
import BoardUserBiskCard from '../BoardUserBiskCard/BoardUserBiskCard';
import { Board } from '../../../models/board';
import './BoardResultsList.css'

interface BoardResultsListProps {
    blurred: boolean;
    onShopRedirect: () => void;
}

const BoardResultsList: React.FC<BoardResultsListProps> = ({ blurred, onShopRedirect }) => {
    const { isAuthenticated } = useAuth();
    const {
        boards,
        boardSelected,
        boardPeriodSelected,
        boardSortSelected,
        boardFullSelected,
        boardResults,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        error,
        handleBoardChange,
        handleBoardPeriodChange,
        handleBoardSortChange,
        getMoreBoardResults,
        refetch
    } = useBoardResults();

    const parentRef = useRef<HTMLDivElement>(null);

    // Memoize the virtualizer options
    const virtualizerOptions = useMemo(() => ({
        count: hasNextPage ? boardResults.length + 1 : boardResults.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 100,
        overscan: 10, // Increased overscan for smoother scrolling
        initialRect: { width: 0, height: 0 }, // Add initial dimensions
    }), [hasNextPage, boardResults.length]);

    const rowVirtualizer = useVirtualizer(virtualizerOptions);

    // Memoize the loadMore callback
    const loadMore = useCallback(() => {
        if (!isFetchingNextPage && hasNextPage) {
            getMoreBoardResults();
        }
    }, [isFetchingNextPage, hasNextPage, getMoreBoardResults]);

    // Debounced scroll handler
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        const handleScroll = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                const virtualItems = rowVirtualizer.getVirtualItems();
                const lastItem = virtualItems[virtualItems.length - 1];
                if (lastItem && lastItem.index >= boardResults.length - 1) {
                    loadMore();
                }
            }, 100);
        };

        const scrollElement = parentRef.current;
        if (scrollElement) {
            scrollElement.addEventListener('scroll', handleScroll, { passive: true });
            return () => {
                if (timeoutId) clearTimeout(timeoutId);
                scrollElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [boardResults.length, loadMore, rowVirtualizer]);

    // Memoize the renderItem function
    const renderItem = useCallback((index: number) => {
        const isLoaderRow = index > boardResults.length - 1;

        if (index === 0) {
            return boardFullSelected?.board_type === BoardType.IDX_INFO || boardFullSelected?.board_type === BoardType.USER_INFO ? (
                <BoardIDXInfoCard result={boardResults[0]} blurred={false} />
            ) : (
                <BoardUserBiskCard result={boardResults[0]} blurred={false} isAuthed={isAuthenticated} />
            );
        }

        if (index === 1 && blurred && boardFullSelected && !boardFullSelected.public) {
            return (
                <ShopRedirect
                    title="Head to the Smoke Shack and Sign Up for the Tow Rope Pass to View Leaderboards!"
                    shopRedirect={onShopRedirect}
                />
            );
        }

        if (isLoaderRow) {
            return hasNextPage ? (
                <div className="p-4 text-center">Loading more...</div>
            ) : null;
        }

        const result = boardResults[index];
        const isBlurred = boardFullSelected ? (boardFullSelected.public ? false : blurred) : blurred;

        return boardFullSelected?.board_type === BoardType.IDX_INFO || boardFullSelected?.board_type === BoardType.USER_INFO ? (
            <BoardIDXInfoCard result={result} blurred={isBlurred} />
        ) : (
            <BoardUserBiskCard result={result} blurred={isBlurred} isAuthed={isAuthenticated} />
        );
    }, [boardResults, boardFullSelected, blurred, isAuthenticated, hasNextPage, onShopRedirect]);

    const handleRefresh = async (event: CustomEvent) => {
        await refetch();
        event.detail.complete();
    };

    // Memoize the header options
    const headerOptions = useMemo(() => ({
        boards,
        boardSelected,
        boardPeriodSelected,
        boardSortSelected,
        boardFullSelected
    }), [boards, boardSelected, boardPeriodSelected, boardSortSelected, boardFullSelected]);

    if (status === 'pending') return <BoardLoading />;
    if (status === 'error') return <div>Error: {(error as Error).message}</div>;

    return (
        <IonContent scrollY={false}>
            <IonRefresher onIonRefresh={handleRefresh} slot="fixed">
                <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            <div className="flex flex-row items-center justify-between my-4 mx-8">
                <div>
                    <IonSelect
                        className="w-full max-w-xs"
                        aria-label="Board"
                        color="theme-alternative"
                        interface="popover"
                        placeholder="Board"
                        value={headerOptions.boardSelected}
                        onIonChange={(e) => handleBoardChange(e.detail.value)}
                    >
                        {headerOptions.boards.map((board: Board) => (
                            <IonSelectOption key={board.id} value={board.name}>{board.name}</IonSelectOption>
                        ))}
                    </IonSelect>
                </div>
                <div>
                    <IonSelect
                        className="w-full max-w-xs"
                        aria-label="Period"
                        color={"theme-alternative"}
                        interface="popover"
                        placeholder="Period"
                        value={headerOptions.boardPeriodSelected}
                        onIonChange={(e) => handleBoardPeriodChange(e.detail.value)}
                    >
                        {boardFullSelected?.periods.map((option) => (
                            <IonSelectOption key={option} value={option}>{option}</IonSelectOption>
                        ))}
                    </IonSelect>
                </div>
                <div>
                    <IonSelect
                        className="w-full max-w-xs"
                        aria-label="Sort"
                        color={"theme-alternative"}
                        interface="popover"
                        placeholder="Sort"
                        value={headerOptions.boardSortSelected}
                        onIonChange={(e) => handleBoardSortChange(e.detail.value)}
                    >
                        {boardFullSelected?.sort.map((option) => (
                            <IonSelectOption key={option} value={option}>{option}</IonSelectOption>
                        ))}
                    </IonSelect>
                </div>
            </div>
            <div
                ref={parentRef}
                style={{
                    height: 'calc(100% - 60px)',
                    overflow: 'auto',
                    willChange: 'transform', // Optimize for animations
                    WebkitOverflowScrolling: 'touch' // Improve iOS scrolling
                }}
            >
                <div
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                        <div
                            key={virtualRow.index}
                            data-index={virtualRow.index}
                            ref={rowVirtualizer.measureElement}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${virtualRow.start}px)`,
                                willChange: 'transform',
                            }}
                        >
                            {renderItem(virtualRow.index)}
                        </div>
                    ))}
                </div>
            </div>
            {isFetching && !isFetchingNextPage && (
                <div className="text-center p-4">Updating...</div>
            )}
        </IonContent>
    );
};

export default BoardResultsList;