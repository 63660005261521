import { Browser } from "@capacitor/browser";
import { Clipboard } from '@capacitor/clipboard';
import { Share } from "@capacitor/share";
import { OverlayEventDetail } from "@ionic/core/components";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSpinner,
    RefresherEventDetail,
    useIonAlert,
    useIonModal,
    useIonToast
} from "@ionic/react";
import {
    alertCircleOutline,
    briefcaseOutline,
    checkmarkCircleOutline,
    createOutline,
    globeOutline,
    helpCircleOutline,
    linkOutline,
    pencilOutline,
    personAddOutline,
    personRemoveOutline,
    shareOutline,
} from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import CaseModal from "../components/ComponentsProfile/CaseModal/CaseModal";
import EditProfileModal from "../components/ComponentsProfile/EditProfileModal/EditProfileModal";
import ProfileSocial from "../components/ComponentsProfile/ProfileSocial/ProfileSocial";
import ViewProfileFollowInfoButton
    from "../components/ComponentsUI/ViewProfileFollowInfoButton/ViewProfileFollowInfoButton";
import "../global.css";
import { Badge } from "../models/badges";
import { ClipType } from "../models/clipType";
import { SportType } from "../models/sportType";
import { User } from "../models/user";
import { UserTrick } from "../models/user-trick";
import { UserTrickSearch } from "../models/user-trick-search";
import { UserPreferencesConstants } from "../models/userPreferencesConstants";
import { UserType } from "../models/usertype";
import badgesService from "../services/badges.service";
import BadgesService from "../services/badges.service";
import TricksService from "../services/tricks.service";
import userService from "../services/user.service";
import UserService from "../services/user.service";
import { analyzeUserTrickResForUnlisted, arrangeTricksByPreferences } from "../services/utils";
import { AppContext } from "../AppStateProvider";
import ProfilePicture from "../components/ComponentsProfile/ProfilePicture/ProfilePicture";
import BiskProfile from "../components/ComponentsBisk/BiskProfile/BiskProfile";
import UsernameDisplay from "../components/ComponentsUI/Username/UsernameDisplay";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import ProfileTrickCard from "../components/ComponentsProfile/ProfileTrickCard/ProfileTrickCard";
import BlockUser from "../components/ComponentsProfile/BlockUser/BlockUser";
import ReturnToProfile from "../components/ComponentsProfile/ReturnToProfile/ReturnToProfile";
import useAuthService from "../hooks/useAuthService";
import SettingsService from "../services/settings.service";
import IonSpinnerMainContent from "../components/ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import { useAuth } from "../AuthProvider";
import LoadingProfileComponent from "../components/ComponentsProfile/LoadingProfileComponent/LoadingProfileComponent";
import LoginButtonPage from "./LoginButtonPage";
import IDXButtonIcon from "../components/ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import IDXProfileButton from "../components/ComponentsUI/IDXProfileButton/IDXProfileButton";
import ToolbarSearchBarProfile from "../components/ComponentsUI/ToolbarSearchBarProfile/ToolbarSearchBarProfile";
import { useAppState } from "../AppListenerProvider";
import ProfileVirtualGrid from "../components/ComponentsProfile/ProfileVirtualGrid/ProfileVirtualGrid";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ProfileVirtualSortableGrid from "../components/ComponentsProfile/ProfileVirtualSortableGrid/ProfileVirtualSortableGrid";
import SavedTrickCard from "../components/ComponentsProfile/SavedTrickCard/SavedTrickCard";
import { is } from "date-fns/locale";
import { Keyboard } from "@capacitor/keyboard";
import EclipticSearchbar from "../components/ComponentsUI/EclipticSearchbar/EclipticSearchbar";

interface ProfileParams {
    username?: string;
}

interface ProfileProps extends RouteComponentProps<ProfileParams> { }

const Profile: React.FC<ProfileProps> = ({ match }) => {
    const { state, dispatch } = useContext(AppContext);
    const { isNative } = useAppState();
    const history = useHistory();
    const { isAuthenticated, user, isLoading } = useAuth();
    const { login } = useAuthService();
    const { username } = match.params;
    const queryClient = useQueryClient();

    //use effect to tricksservice call to getsavedtricks
    const [presentAlert] = useIonAlert();
    const [presentToast] = useIonToast();

    const tabs = ['Clips', 'Lines', 'Edits', 'Saved'];
    const [activeTab, setActiveTab] = useState(tabs[0]);


    // Set Up user being searched for
    const [profileLoading, setProfileLoading] = useState(false);
    const [userProfileUrl, setUserProfileUrl] = useState(undefined);
    const [editProfile, setEditProfile] = useState(undefined);
    const [profileError, setProfileError] = useState(false);

    const { data: userProfile, isLoading: isLoadingProfile, isError: isErrorProfile } = useQuery({
        queryKey: ['userProfile', username],
        queryFn: () => UserService.getUserByUsername(username),
        enabled: !!username
    });

    // Add a refresh mutation
    const refreshMutation = useMutation({
        mutationFn: async () => {
            await Promise.all([
                queryClient.refetchQueries({ queryKey: ['userProfile', username] }),
                queryClient.refetchQueries({ queryKey: ['userTricks', userProfile?.id] }),
                queryClient.refetchQueries({ queryKey: ['savedVideos', userProfile?.id] }),
                queryClient.refetchQueries({ queryKey: ['userPreferences', userProfile?.id] }),
                queryClient.refetchQueries({ queryKey: ['userBadges', userProfile?.id] }),
            ]);
        },
        onError: (error) => {
            console.error('Error refreshing profile data:', error);
        }
    });

    const [tricksLoading, setTricksLoading] = useState(true);

    const { data: userTricksData, isLoading: userTricksLoading } = useQuery({
        queryKey: ['userTricks', userProfile?.id],
        queryFn: () => TricksService.getUserTricks(userProfile?.id),
        enabled: !!userProfile?.id,
        staleTime: 1000 * 60 * 5,
    });

    const { data: userPreferencesData } = useQuery({
        queryKey: ['userPreferences', userProfile?.id],
        queryFn: () => UserService.getUserPreferences(userProfile?.id),
        enabled: !!userProfile?.id,
    });

    const { data: savedVideos, isLoading: savesLoading } = useQuery({
        queryKey: ['savedVideos', userProfile?.id],
        queryFn: () => UserService.getUserSavedVideos(userProfile?.id),
        enabled: !!userProfile?.id,
    });

    const { data: badges, isLoading: badgesLoading } = useQuery({
        queryKey: ['userBadges', userProfile?.id],
        queryFn: async () => {
            const badgePromises = userProfile?.profile?.badges.map((badgeId: any) =>
                BadgesService.getBadgeByID(badgeId)
            ) || [];
            console.log("Badge Promises: ", badgePromises);
            return Promise.all(badgePromises);
        },
        enabled: !!userProfile?.id && !!userProfile?.profile?.badges,
    });

    const [bagOfTricksFilter, setBagOfTricksFilter] = useState<string>("");
    const [userTricks, setUserTricks] = useState([]);
    const [editSortingUserTricks, setEditSortingUserTricks] = useState(false);

    const [stashOfLinesFilter, setStashOfLinesFilter] = useState<string>("");
    const [userLines, setUserLines] = useState([]);
    const [editSortingUserLines, setEditSortingUserLines] = useState(false);

    const [galleryOfEditsFilter, setGalleryOfEditsFilter] = useState<string>("");
    const [userEdits, setUserEdits] = useState([]);
    const [editSortingUserEdits, setEditSortingUserEdits] = useState(false);

    const [claimProfile, setClaimProfile] = useState(false);
    const [followingUser, setFollowingUser] = useState(false);
    const [followingUserLoading, setFollowingUserLoading] = useState(false);

    const [savedVideoFilter, setSavedVideoFilter] = useState<string>("");

    const [followersCount, setFollowersCount] = useState<number>(0);
    const [followingCount, setFollowingCount] = useState<number>(0);

    const [userBlocked, setUserBlocked] = useState(false);
    const [profileBlocking, setProfileBlocking] = useState(false);

    const onEditProfileSubmit = async (name: string, username: string, homeMountain: string, sports: string[],
        trickInterests: string[], instagram: string, tiktok: string, youtube: string,
        website: string, website_label: string, discord: string, twitter: string,
        push_notifications: string, setup: string) => {
        const returnedUser: User = await userService.upsertUserProfileInformation(state.user.id,
            name, username, homeMountain, sports, trickInterests, instagram, tiktok, youtube, website, website_label,
            discord, twitter, push_notifications, setup);
        dispatch({ type: 'setUser', payload: returnedUser });
        history.push(`/profile/${returnedUser.username}`);
        await refreshMutation.mutateAsync();
    }

    const onClickTrickFromSuggestedTricks = async (trick: string) => {
        history.push(`/search/${trick}`);
    }

    const setUserBadgesFromSorting = async (userBadgesSorted: Badge[]) => {
        const badgeIds = userBadgesSorted.map(badge => badge.id);

        await badgesService.reorderUserBadges(state.user.id, badgeIds);

        // Update the cache
        queryClient.setQueryData(['userBadges', userProfile?.id], userBadgesSorted);

        // Optionally, if the order is also stored in the user profile, update that as well
        queryClient.setQueryData(['userProfile', username], (oldData: User | undefined) => {
            if (!oldData) return oldData;
            return {
                ...oldData,
                profile: {
                    ...oldData.profile,
                    badges: badgeIds
                }
            };
        });
    }

    const shopRedirect = () => {
        history.push('/shop');
    }

    const blockUser = async (blocked_id: string, block: boolean) => {
        const res = await UserService.blockUser(state.user.id, blocked_id, block);
        setUserBlocked(res);
        const blockedUsers: string[] = await UserService.getBlockedUsers(state.user.id);
        dispatch({ type: 'setBlockedUsers', payload: blockedUsers });
    }

    const reportUser = async (reported_id: string) => {
        const res = await SettingsService.sendFeedback(state.user.id, "Username: " + state.user.username + " wishes to report " + userProfile?.username + " with the id : " + reported_id);
        await presentToastHandler(`${userProfile?.username} has been reported`);
    }

    const getIfBlockedUser = async (user_id: string, blocked_id: string) => {
        const res = await UserService.checkIfBlockedUser(user_id, blocked_id);
        setUserBlocked(res);
    }

    const getIfProfileBlocking = async (user_id: string, blocker_id: string) => {
        const res = await UserService.checkIfBlockingUser(user_id, blocker_id);
        setProfileBlocking(res);
    }

    const goToSelfProfile = (username: string) => {
        history.push(`/profile/${username}`);
    }

    const [presentSocial, dismissSocial] = useIonModal(ProfileSocial, {
        onDismiss: (data: string, role: string) => dismissSocial(data, role),
        userProfileSocial: { ...userProfile },
        displayBlock: !editProfile,
        userBlocked: userBlocked,
        onBlockUser: blockUser,
        onReportUser: reportUser,
        loggedIn: isAuthenticated,
    });

    const [presentTrophy, dismissTrophy] = useIonModal(CaseModal, {
        onDismiss: (data: string, role: string) => dismissTrophy(data, role),
        badges: badges,
        caseTitle: editProfile ? "Your Case" : `${userProfile?.username}'s Case`,
        sports: userProfile?.profile?.sports,
        editProfile: editProfile,
        userProfileId: userProfile?.id,
        isNative: isNative,
        username: userProfile?.username,
        setBadgeOrder: setUserBadgesFromSorting,
        onClickTrickHandler: onClickTrickFromSuggestedTricks,
        shopRedirect: shopRedirect,
    });

    const [presentEditProfileModal, dismissEditProfileModal] = useIonModal(EditProfileModal, {
        onDismiss: (data: string, role: string) => dismissEditProfileModal(data, role),
        onEditProfileSubmit: onEditProfileSubmit,
        userProfileEdit: { ...userProfile }
    });

    const [presentFollowing, dismissFollowing] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowing(data, role),
        userProfileId: userProfile?.id,
        followers: false,
    })

    const [presentFollowers, dismissFollowers] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowers(data, role),
        userProfileId: userProfile?.id,
        followers: true,
    })

    const writeProfileUrlToClipboard = async () => {
        if (isNative) {
            let shareOptions: any = {
                title: "Ecliptic // " + userProfile?.username,
                dialogTitle: "Ecliptic // " + userProfile?.username,
                url: userProfileUrl,
            }
            await Share.share(shareOptions)
        } else {
            await Clipboard.write({
                url: userProfileUrl
            })
            await presentToastHandler("Copied Link to Profile!");
        }
    };

    const resetProfile = async () => {
        console.log("resetting userprofile");
        setProfileError(false);

        setEditSortingUserTricks(false);

        setEditSortingUserLines(false);

        setEditSortingUserEdits(false);

        setBagOfTricksFilter("");
        setStashOfLinesFilter("");
        setGalleryOfEditsFilter("");
        setSavedVideoFilter("");

        setActiveTab(tabs[0]);

        setProfileLoading(false);
        setUserProfileUrl(undefined);
        setFollowersCount(0);
        setFollowingCount(0);
        setClaimProfile(false);
        setEditProfile(undefined);
        setFollowingUser(false);
        setUserBlocked(false);
        setProfileBlocking(false);
    }

    const checkIfFollowingUser = async (loggedInUserId: string, responseId: string) => {
        const isFollowing = await UserService.checkIfUserFollowingOtherUser(loggedInUserId, responseId);
        setFollowingUser(isFollowing);
    }

    const processUserTricks = (tricks: UserTrick[]) => {
        setTricksLoading(true);
        const userTricksFiltered = tricks.filter((userTrick: UserTrick) => userTrick.clip_type === ClipType.CLIP || (userTrick.clip_type !== ClipType.LINE && userTrick.clip_type !== ClipType.EDIT));
        const userLinesRes = tricks.filter((userTrick: UserTrick) => userTrick.clip_type === ClipType.LINE);
        const userEditRes = tricks.filter((userTrick: UserTrick) => userTrick.clip_type === ClipType.EDIT);

        const userTricksToDisplay = analyzeUserTrickResForUnlisted(userTricksFiltered, editProfile);
        const userLinesToDisplay = analyzeUserTrickResForUnlisted(userLinesRes, editProfile);
        const userEditsToDisplay = analyzeUserTrickResForUnlisted(userEditRes, editProfile);

        if (userPreferencesData) {
            const userTrickPreferences: Map<string, number> = userPreferencesData[UserPreferencesConstants.TRICKS] ? new Map(Object.entries(userPreferencesData[UserPreferencesConstants.TRICKS])) : new Map();
            const userLinesPreferences: Map<string, number> = userPreferencesData[UserPreferencesConstants.LINES] ? new Map(Object.entries(userPreferencesData[UserPreferencesConstants.LINES])) : new Map();
            const userEditsPreferences: Map<string, number> = userPreferencesData[UserPreferencesConstants.EDITS] ? new Map(Object.entries(userPreferencesData[UserPreferencesConstants.EDITS])) : new Map();

            if (userTrickPreferences.size > 0) {
                setUserTricks(arrangeTricksByPreferences(userTricksToDisplay, userTrickPreferences));
            } else {
                setUserTricks(userTricksToDisplay);
            }

            if (userLinesPreferences.size > 0) {
                setUserLines(arrangeTricksByPreferences(userLinesToDisplay, userLinesPreferences));
            } else {
                setUserLines(userLinesToDisplay);
            }

            if (userEditsPreferences.size > 0) {
                setUserEdits(arrangeTricksByPreferences(userEditsToDisplay, userEditsPreferences));
            } else {
                setUserEdits(userEditsToDisplay);
            }
        } else {
            setUserTricks(userTricksToDisplay);
            setUserLines(userLinesToDisplay);
            setUserEdits(userEditsToDisplay);
        }
        setTricksLoading(false);
    };


    const getUserProfile = async () => {
        await resetProfile();
        setProfileLoading(true);

        setUserProfileUrl(`${process.env.REACT_APP_IDX_URL}/profile/${userProfile.username}`);

        if (userProfile) {
            const followersCountRes = await UserService.getFollowCount(userProfile.id, true);
            const followingCountRes = await UserService.getFollowCount(userProfile.id, false);
            setFollowersCount(followersCountRes);
            setFollowingCount(followingCountRes);

            setClaimProfile(userProfile.auth_id === null || userProfile.auth_id === undefined || userProfile.auth_id === '');

            let editProfileRes = false;

            if (!isAuthenticated) {
                setEditProfile(false);
            } else {
                if (userProfile.auth_id === user.uid) {
                    setEditProfile(true);
                    editProfileRes = true;
                }

                await checkIfFollowingUser(state.user.id, userProfile.id);
                await getIfBlockedUser(state.user.id, userProfile.id);
                await getIfProfileBlocking(userProfile.id, state.user.id);
            }
        } else {
            setProfileError(true);
        }
        setProfileLoading(false);
        window.prerenderReady = true;
    }

    useEffect(() => {
        console.log('Processing user tricks', { userTricksDataLength: userTricksData?.length });
        if (userTricksData && userPreferencesData) {
            processUserTricks(userTricksData);
        }
    }, [userTricksData, editProfile, userPreferencesData]);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            console.log('match.params:', match.params);
            if (!username && state.user.username) {
                console.log("No username in URL, but user is logged in");
            } else if (username && !isLoadingProfile) {
                getUserProfile();
            } else if (username && isErrorProfile) {
                setProfileError(true);
            }
        }
    }, [isLoading, username, user?.uid, isAuthenticated, isLoadingProfile, isErrorProfile, state.user.id, state.user.username, history, match.params.username]);

    function openSocialModal() {
        presentSocial({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openTrophyModal() {
        presentTrophy({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openEditProfileModal() {
        presentEditProfileModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openFollowingModal() {
        presentFollowing({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    function openFollowersModal() {
        presentFollowers({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onSocialProfileClick = async () => {
        // Open Modal;
        openSocialModal();
    };

    const onTrophyProfileClick = async () => {
        // Open Modal;
        openTrophyModal();
    }

    const onFollowingProfileClick = async () => {
        // Open Modal;
        openFollowingModal();
    };

    const onFollowersProfileClick = async () => {
        // Open Modal;
        openFollowersModal();
    };

    const onFollowUser = async (followingUserState: boolean) => {
        if (isAuthenticated) {
            setFollowingUserLoading(true);

            const response: User = await UserService.followUserV2(state.user.id, userProfile.id, !followingUserState);
            if (response) {
                setFollowingUserLoading(false);
                setFollowingUser(!followingUserState);

                const followersCountRes = await UserService.getFollowCount(userProfile.id, true);
                const followingCountRes = await UserService.getFollowCount(userProfile.id, false);

                setFollowersCount(followersCountRes);
                setFollowingCount(followingCountRes);

                const followCountForLoggedInUser = await UserService.getFollowCount(state.user.id, false);
                dispatch({ type: 'setFollowing', payload: followCountForLoggedInUser });
            }
        } else {
            setFollowingUserLoading(false);
            await presentAlert({
                header: 'Login Required',
                message: 'You must be logged in to follow a user',
                buttons: [
                    'Cancel',
                    {
                        text: 'Login',
                        handler: async () => {
                            await login();
                        }
                    }
                ]
            });
        }
    }

    const onTrickSelection = (trick: UserTrick): void => {
        console.log("CLICKING TRICK");
        history.push('/clip/' + trick.user_id + '/' + trick.trick_id);
    }

    const onResetProfile = async (event: CustomEvent<RefresherEventDetail>) => {
        try {
            await refreshMutation.mutateAsync();
        } finally {
            event.detail.complete();
        }
    }

    const goToProfileLink = async () => {
        if (isNative) {
            await Browser.open({
                url: userProfile.profile.website,
                presentationStyle: 'popover',
                'toolbarColor': '#000000'
            });
        } else {
            window.open(userProfile.profile.website, '_blank');
        }
    }

    const handleSortingUserTricks = async (sortableTricks: UserTrick[], segment: string) => {
        if (editProfile) {
            const trickIds: string[] = sortableTricks.map((trick) => trick.trick_id);
            const positions: number[] = Array.from(sortableTricks.entries()).map(([index]) => index);
            await UserService.upsertUserPreferences(user.uid, trickIds, positions, segment).then((response) => {
                if (response) {
                    console.log('Successfully updated user trick preferences');
                    console.log(response);
                }
            });

            queryClient.setQueryData(['userTricks', userProfile?.id], (oldData: UserTrick[] | undefined) => {
                if (!oldData) return sortableTricks;
                return oldData.map(trick => {
                    const updatedTrick = sortableTricks.find(t => t.trick_id === trick.trick_id);
                    return updatedTrick || trick;
                });
            });

            if (segment === UserPreferencesConstants.TRICKS) {
                setUserTricks(sortableTricks);
            } else if (segment === UserPreferencesConstants.LINES) {
                setUserLines(sortableTricks);
            } else if (segment === UserPreferencesConstants.EDITS) {
                setUserEdits(sortableTricks);
            }
        }
    }

    const presentToastHandler = async (message: string) => {
        await presentToast({
            message: message,
            duration: 5000,
            position: 'top',
            icon: alertCircleOutline,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    if (isLoading || profileLoading || tricksLoading || (isAuthenticated && !userProfile && !profileError)) {
        return (
            <IonPage>
                <CustomHelmet
                    title={userProfile?.username ? "Ecliptic // " + userProfile?.username : "Ecliptic // View Profile"}
                    description={userProfile?.username ? `View ${userProfile?.username}'s Bag of Tricks` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                    image={userProfile?.profile?.profile_pic ? userProfile.profile.profile_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/profile/${username}`} />
                <ToolbarSearchBarProfile username={username} />
                <IonContent>
                    <LoadingProfileComponent />
                </IonContent>
            </IonPage>
        );
    }

    if (!isAuthenticated) {
        return (<LoginButtonPage
            title={username ? `Create Your Profile to Build Your Bag of Tricks and View ${username}'s Ecliptic Profile` : "Create Your Profile to Build Your Bag of Tricks and Interact With the Ecliptic Community"}
            upload={false} profile={true} brand={false} />)
    }

    if (profileError) {
        return (
            <IonPage>
                <ToolbarSearchBarProfile username={username} />
                <IonContent>
                    <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                        <div className="flex-col text-center">
                            <div>There was an error loading this profile.</div>
                        </div>
                        <div className="flex-col text-center">
                            <IonButton onClick={() => {
                                history.push('/home');
                            }} color="favorite">Go Back to Ecliptic</IonButton>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        )
    }

    return (
        <IonPage>
            <CustomHelmet
                title={userProfile?.username ? "Ecliptic // " + userProfile?.username : "Ecliptic // View Profile"}
                description={userProfile?.username ? `View ${userProfile?.username}'s Bag of Tricks` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                image={userProfile?.profile?.profile_pic ? userProfile.profile.profile_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/profile/${username}`} />
            <ToolbarSearchBarProfile username={username} />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={onResetProfile}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="flex flex-col mx-2 mt-2 mb-0 gap-3 font-bold text-2xl">
                    <div className="grid grid-cols-[auto,1fr,auto] gap-x-4 items-center w-full">
                        {/* Box 1: Pic + Name + Sport + Location */}
                        <div className="flex flex-col items-center">
                            <ProfilePicture src={userProfile?.profile?.profile_pic} editProfile={editProfile}
                                isNative={isNative}></ProfilePicture>
                        </div>
                        <div className="flex flex-col gap-y-0.5">
                            {/* User Info */}
                            <div className="flex flex-row text-2xl gap-x-1 items-center">
                                <UsernameDisplay username={userProfile?.username} className={""}
                                    userId={userProfile?.id} loggedInId={state.user?.id} />
                                {
                                    claimProfile &&
                                    <IDXButtonIcon size="text-2xl" color="dark" icon={helpCircleOutline}
                                        onClick={() => presentToastHandler("Is This You? DM Us on Instagram @idx.style or Email Us at mia@idx.style to claim or remove your profile")} />
                                }
                            </div>
                            <div className="text-base">{userProfile?.name}</div>
                            <div className="text-base">{userProfile?.profile?.home_mountain}</div>
                            <div className="flex flex-row items-center gap-x-1">
                                {/* Website Link - TODO: ADD SPONSORS HERE */}
                                {userProfile?.profile?.website && (
                                    <IonIcon icon={linkOutline} size="small"></IonIcon>)}
                                {userProfile?.profile?.website && (
                                    <div className="flex flex-row gap-2 font-normal text-base"
                                        onClick={goToProfileLink}>
                                        <a>{userProfile?.profile?.website_name || userProfile?.profile?.website}</a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col items-end space-y-0.5">
                            <div className="flex flex-col items-end justify-start space-y-0.5">
                                <div className="flex flex-col gap-0.5 items-end">
                                    <IDXProfileButton onClick={onSocialProfileClick} icon={globeOutline}
                                        spanText={"Info"} />
                                    <IDXProfileButton onClick={onTrophyProfileClick} icon={briefcaseOutline}
                                        spanText={"Case"} />
                                    <BiskProfile senderId={state?.user?.id} recipientId={userProfile?.id}
                                        recipientUsername={userProfile?.username}
                                        isAuthed={isAuthenticated} component="profile"
                                        email={state?.user?.email} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-x-4">
                        <div>
                            {/* Sports Icons */}
                            <div className="flex flex-row items-end gap-x-4">
                                {userProfile?.profile?.sports?.includes(SportType.SNOWBOARDING) && (
                                    <button
                                        className="w-[1.05em] h-[1.05em] rounded-full overflow-hidden flex items-center justify-center">
                                        <span role="img" aria-label="Snowboarding" className="text-lg">🏂</span>
                                    </button>
                                )}
                                {userProfile?.profile?.sports?.includes(SportType.SKIING) && (
                                    <button
                                        className="w-[1.05em] h-[1.05em] rounded-full overflow-hidden flex items-center justify-center">
                                        <span role="img" aria-label="Skiing" className="text-lg">⛷️</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        {badges && !badgesLoading && (
                            <div className="flex flex-row gap-x-4 items-center">
                                {badges.slice(0, 8 - userProfile?.profile?.sports.length).map((badge, index) => (
                                    <button
                                        className="w-[1.05em] h-[1.05em] rounded-full overflow-hidden"
                                        onClick={openTrophyModal}
                                        key={index}
                                    >
                                        <img
                                            src={badge.link}
                                            alt={badge.name}
                                            className="object-cover w-full h-full"
                                        />
                                    </button>
                                ))}
                                {badges.length > (8 - userProfile?.profile?.sports.length) && (
                                    <button className="w-[1.05em] h-[1.05em]" onClick={openTrophyModal}>
                                        <span role="img" aria-label="More Badges" style={{ color: 'white' }}>
                                            …
                                        </span>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row mb-3 justify-between items-center flex-nowrap">
                        <div className="flex flex-nowrap">
                            <IonButton fill="solid" size="small" color="favorite"
                                onClick={() => onFollowingProfileClick()}>
                                Following: {followingCount}
                            </IonButton>
                            {
                                editProfile || (state.isSubscribed) ?
                                    <IonButton fill="solid" size="small" color="favorite"
                                        onClick={() => onFollowersProfileClick()}>
                                        Followers: {followersCount}
                                    </IonButton>
                                    :
                                    null
                            }
                        </div>
                        <div className="flex flex-nowrap">
                            <IDXProfileButton onClick={writeProfileUrlToClipboard} icon={shareOutline}
                                spanText={"Share"} />
                            {editProfile ?
                                <IDXProfileButton onClick={openEditProfileModal} icon={pencilOutline}
                                    spanText={"Edit"} fill="solid" color="favorite" /> :
                                followingUserLoading ?
                                    <IonButton fill="clear" size="small">
                                        <IonSpinner name="bubbles" color="theme-alternative"></IonSpinner>
                                    </IonButton> :
                                    (
                                        !followingUser || !isAuthenticated ?
                                            <IDXProfileButton onClick={() => onFollowUser(followingUser)}
                                                icon={personAddOutline}
                                                spanText={"Follow"} fill="solid" color="favorite" /> :
                                            <IDXProfileButton onClick={() => onFollowUser(followingUser)}
                                                icon={personRemoveOutline}
                                                spanText={"Unfollow"} fill="solid" color="favorite" />
                                    )
                            }
                        </div>
                    </div>
                </div>
                {
                    userBlocked || profileBlocking ?
                        <div className="flex flex-row my-2 justify-center">
                            {
                                userBlocked &&
                                <BlockUser blockedUserId={userProfile?.id} userBlocked={userBlocked}
                                    onBlockUser={blockUser} username={userProfile?.username}
                                    loggedIn={isAuthenticated} />
                            }
                            {
                                profileBlocking &&
                                <ReturnToProfile username={state?.user?.username}
                                    usernameCallback={goToSelfProfile} />
                            }
                        </div>
                        :
                        <div>
                            <div
                                className="flex justify-center space-x-4 border-b-2 border-gray-500 text-base font-bold">
                                {tabs.map((tab, index) => (
                                    <div key={index}
                                        className={activeTab === tab ? 'border-b-4 border-[#5963A7]' : ''}>
                                        <button
                                            key={tab}
                                            className={`py-2 px-2.5 text-xl font-bold ${activeTab === tab ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-500 hover:text-white'}`}
                                            onClick={() => {
                                                setSavedVideoFilter("");
                                                setBagOfTricksFilter("");
                                                setStashOfLinesFilter("");
                                                setGalleryOfEditsFilter("");
                                                setActiveTab(tab)
                                            }}>
                                            {tab} {!tricksLoading && <span
                                                className="text-sm">{tab === 'Clips' ? ` ${userTricks.length}` : tab === 'Lines' ? ` ${userLines.length}` : tab === 'Edits' ? ` ${userEdits.length}` : ''}</span>}
                                        </button>
                                    </div>

                                ))}
                            </div>
                            <div
                                className="flex flex-row justify-between items-center mb-0.5 mx-1">
                                <EclipticSearchbar
                                    onIonInput={(e) => {
                                        if (activeTab === 'Clips') {
                                            setBagOfTricksFilter(e.detail.value)
                                        } else if (activeTab === 'Lines') {
                                            setStashOfLinesFilter(e.detail.value)
                                        } else if (activeTab === 'Edits') {
                                            setGalleryOfEditsFilter(e.detail.value)
                                        } else if (activeTab === 'Saves') {
                                            setSavedVideoFilter(e.detail.value)
                                        } else {
                                            return;
                                        }
                                    }}
                                    showClearButton="focus"
                                    showCancelButton="focus"
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                    id="searchText"
                                    debounce={0}
                                    animated={true}/>
                                  {isNative && editProfile && userTricks.length > 0 && activeTab === 'Clips' ?
                                    <IDXProfileButton
                                        color={!editSortingUserTricks ? "favorite-secondary" : "success"}
                                        icon={!editSortingUserTricks ? createOutline : checkmarkCircleOutline}
                                        spanText={''}
                                        onClick={() => setEditSortingUserTricks(!editSortingUserTricks)} />
                                    : null
                                }
                                {isNative && editProfile && userLines.length > 0 && activeTab === 'Lines' ?
                                    <IDXProfileButton
                                        color={!editSortingUserLines ? "favorite-secondary" : "success"}
                                        icon={!editSortingUserLines ? createOutline : checkmarkCircleOutline}
                                        spanText={''}
                                        onClick={() => setEditSortingUserLines(!editSortingUserLines)} />
                                    : null
                                }
                                {isNative && editProfile && userEdits.length > 0 && activeTab === 'Edits' ?
                                    <IDXProfileButton
                                        color={!editSortingUserEdits ? "favorite-secondary" : "success"}
                                        icon={!editSortingUserEdits ? createOutline : checkmarkCircleOutline}
                                        spanText={''}
                                        onClick={() => setEditSortingUserEdits(!editSortingUserEdits)} />
                                    : null
                                }
                            </div>
                            <div className="p-1">
                                {
                                    activeTab === 'Clips' &&
                                    <>
                                        {
                                            !tricksLoading ?
                                                (
                                                    userTricks.length > 0 ?
                                                        <>
                                                            <ProfileVirtualSortableGrid<UserTrick>
                                                                key={`${activeTab}-grid-${userTricks.length}`} // Add this key
                                                                items={userTricks}
                                                                setItems={(newState) => {
                                                                    handleSortingUserTricks(newState, UserPreferencesConstants.TRICKS);
                                                                }}
                                                                renderItem={(trick) => (
                                                                    <div className={`${trick.tags.includes("Unlisted") ? "opacity-25" : ""}`}>
                                                                        <ProfileTrickCard trick={trick} />
                                                                    </div>
                                                                )}
                                                                getItemId={(trick) => trick.trick_id}
                                                                filter={(trick) =>
                                                                    trick.trick_name.toLowerCase().includes(bagOfTricksFilter.toLowerCase()) ||
                                                                    (trick.location && trick.location.toLowerCase().includes(bagOfTricksFilter.toLowerCase()))
                                                                }
                                                                disabled={isNative ? (!editSortingUserTricks || bagOfTricksFilter.length > 0 || !editProfile) : (bagOfTricksFilter.length > 0 || !editProfile)}
                                                                isNative={isNative}
                                                                onClick={onTrickSelection}
                                                                activeTab={activeTab}
                                                            />
                                                        </> :
                                                        <div
                                                            className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                            <div>{editProfile ? "Upload to build your bag of tricks 🤑" : `Looks like ${userProfile?.username}'s Bag of Tricks is empty 😔`}</div>
                                                        </div>
                                                )
                                                :
                                                <div
                                                    className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                    <IonSpinnerMainContent className={"my-2"}
                                                        size="medium" /> {/* You can choose other spinner names as well */}
                                                </div>
                                        }
                                    </>
                                }
                                {
                                    activeTab === 'Lines' &&
                                    <>
                                        {
                                            !tricksLoading ?
                                                (
                                                    userLines.length > 0 ?
                                                        <>
                                                            <ProfileVirtualSortableGrid<UserTrick>
                                                                key={`${activeTab}-grid-${userTricks.length}`} // Add this key
                                                                items={userLines}
                                                                setItems={(newState) => {
                                                                    handleSortingUserTricks(newState, UserPreferencesConstants.LINES)
                                                                }}
                                                                renderItem={(trick) => (
                                                                    <div
                                                                        className={trick.tags.includes("Unlisted") ? "opacity-25" : ""}
                                                                    >
                                                                        <ProfileTrickCard trick={trick} />
                                                                    </div>
                                                                )}
                                                                getItemId={(trick) => trick.trick_id}
                                                                filter={(trick) =>
                                                                    trick.trick_name.toLowerCase().includes(stashOfLinesFilter.toLowerCase()) ||
                                                                    (trick.location && trick.location.toLowerCase().includes(stashOfLinesFilter.toLowerCase()))
                                                                }
                                                                disabled={isNative ? (!editSortingUserLines || stashOfLinesFilter.length > 0 || !editProfile) : (stashOfLinesFilter.length > 0 || !editProfile)}
                                                                isNative={isNative}
                                                                onClick={onTrickSelection}
                                                                activeTab={activeTab}
                                                            />
                                                        </> :
                                                        <div
                                                            className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                            <div>{editProfile ? "Upload to build your stash of lines 😮‍💨" : `Looks like ${userProfile?.username}'s Stash of Lines is empty 😥`}</div>
                                                        </div>
                                                )
                                                :
                                                <div
                                                    className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                    <IonSpinnerMainContent className={"my-2"}
                                                        size="medium" /> {/* You can choose other spinner names as well */}

                                                </div>
                                        }
                                    </>
                                }
                                {
                                    activeTab === 'Edits' &&
                                    <>
                                        {
                                            !tricksLoading ?
                                                (
                                                    userEdits.length > 0 ?
                                                        <>
                                                            <ProfileVirtualSortableGrid<UserTrick>
                                                                key={`${activeTab}-grid-${userTricks.length}`} // Add this key
                                                                items={userEdits}
                                                                setItems={(newState) => {
                                                                    handleSortingUserTricks(newState, UserPreferencesConstants.EDITS);
                                                                }}
                                                                renderItem={(trick) => (
                                                                    <div
                                                                        className={trick.tags.includes("Unlisted") ? "opacity-25" : ""}
                                                                    >
                                                                        <ProfileTrickCard trick={trick} />
                                                                    </div>
                                                                )}
                                                                getItemId={(trick) => trick.trick_id}
                                                                filter={(trick) =>
                                                                    trick.trick_name.toLowerCase().includes(galleryOfEditsFilter.toLowerCase()) ||
                                                                    (trick.location && trick.location.toLowerCase().includes(galleryOfEditsFilter.toLowerCase()))
                                                                }
                                                                disabled={isNative ? (!editSortingUserEdits || galleryOfEditsFilter.length > 0 || !editProfile) : (galleryOfEditsFilter.length > 0 || !editProfile)}
                                                                isNative={isNative}
                                                                onClick={onTrickSelection}
                                                                activeTab={activeTab}
                                                            />
                                                        </> :
                                                        <div
                                                            className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                            <div>{editProfile ? "Upload to build your gallery of edits 🤯" : `Looks like ${userProfile?.username}'s Gallery of Edits is empty 😲`}</div>
                                                        </div>
                                                )
                                                :
                                                <div
                                                    className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                    <IonSpinnerMainContent className={"my-2"}
                                                        size="medium" /> {/* You can choose other spinner names as well */}

                                                </div>
                                        }
                                    </>
                                }
                                {activeTab === 'Saved' && (
                                    <>
                                        {!savesLoading ? (
                                            savedVideos.length > 0 ? (
                                                <ProfileVirtualGrid<UserTrickSearch>
                                                    items={savedVideos}
                                                    renderItem={(trick) => <SavedTrickCard trick={trick} />}
                                                    getItemId={(trick) => trick.trick_id}
                                                    filter={(trick) =>
                                                        trick.trick_name.toLowerCase().includes(savedVideoFilter.toLowerCase()) ||
                                                        (trick.location && trick.location.toLowerCase().includes(savedVideoFilter.toLowerCase())) ||
                                                        (trick.username && trick.username.toLowerCase().includes(savedVideoFilter.toLowerCase())) ||
                                                        (trick.name && trick.name.toLowerCase().includes(savedVideoFilter.toLowerCase()))
                                                    } onClick={onTrickSelection}
                                                    activeTab={activeTab}
                                                />
                                            ) : (
                                                <div className="flex flex-col gap-2 m-4 space-y-4 text-base text-center">
                                                    <div>{editProfile ? "Save videos to help with your progression 🅿️" : `It appears ${userProfile?.username} hasn't saved any videos 😵‍💫`}</div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                                <IonSpinnerMainContent className={"my-2"} size="medium" />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default Profile;