import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonInput } from '@ionic/react';
import { useSignUp } from "../SignUpProvider/SignUpProvider";
import { useAppState } from "../../../AppListenerProvider";
import { Keyboard } from "@capacitor/keyboard";
import { validateName } from "../../../services/utils";
import OnboardingPageWrapper from "../OnboardingPageWrapper/OnboardingPageWrapper";

interface NameStepProps {
    onNext: () => void;
    title: string;
    showBackButton: boolean;
    showSkipButton: boolean;
    onBack?: () => void;
}

const NameInput: React.FC<NameStepProps> = ({ onNext, showSkipButton, showBackButton, title, onBack }) => {
    const { signUpData, updateSignUpData } = useSignUp();
    const { isNative, isActive } = useAppState();
    const [firstName, setFirstName] = useState(signUpData.firstName || '');
    const [lastName, setLastName] = useState(signUpData.lastName || '');
    const [error, setError] = useState<string | null>(null);
    const firstNameInputRef = useRef<HTMLIonInputElement>(null);
    const lastNameInputRef = useRef<HTMLIonInputElement>(null);

    useEffect(() => {
        const focusInput = async () => {
            setTimeout(async () => {
                await firstNameInputRef.current?.setFocus();
                if (isNative) {
                    await Keyboard.show();
                }
            }, 300);
        };

        focusInput();

        return () => {
            if (isNative) {
                Keyboard.hide();
            }
        };
    }, [isNative, isActive, firstNameInputRef]);

    const handleFirstNameChange = async (event: CustomEvent) => {
        const value = event.detail.value || '';
        setFirstName(value);
        await updateSignUpData({ firstName: value.trim(), name: `${value.trim()} ${lastName.trim()}` });
    };

    const handleLastNameChange = async (event: CustomEvent) => {
        const value = event.detail.value || '';
        setLastName(value);
        await updateSignUpData({ lastName: value.trim(), name: `${firstName.trim()} ${value.trim()}` });
    };

    const handleSubmit = async () => {
        const firstNameValidation = validateName(firstName);
        if (!firstNameValidation.isValid) {
            setError(`First name: ${firstNameValidation.error}`);
            return;
        }

        setError(null);
        if (isNative) {
            await Keyboard.hide();
        }

        onNext();
    };

    const handleLastNameFocus = async () => {
        await lastNameInputRef.current?.setFocus();
        if (isNative) {
            await Keyboard.show();
        }
    };

    return (
        <OnboardingPageWrapper title={title} showBackButton={showBackButton} showSkipButton={showSkipButton} onBack={onBack}>
            <div className="w-full max-w-md space-y-4 flex flex-col items-center font-bold">
                <div className="text-primary-secondary text-center">
                    Tell us your name so we know what to call you and the homies, crew, and sponsors can find and reward
                    you
                    on
                    Ecliptic.
                </div>
                <div className="relative w-full">
                    <IonInput
                        ref={firstNameInputRef}
                        value={firstName}
                        placeholder="First Name"
                        color="dark"
                        onIonInput={handleFirstNameChange}
                        className="bg-transparent p-4 w-full text-primary-alt text-2xl"
                    />
                </div>
                <div className="relative w-full">
                    <IonInput
                        ref={lastNameInputRef}
                        value={lastName}
                        placeholder="Last Name"
                        color="dark"
                        onIonInput={handleLastNameChange}
                        onFocus={handleLastNameFocus}
                        className="bg-transparent p-4 w-full text-primary-alt text-2xl"
                    />
                </div>
                {error && <div className="text-highlight-danger text-center text-lg">{error}</div>}
                <IonButton
                    expand="block"
                    className="neon-button-alt w-full normal-case font-bold"
                    onClick={handleSubmit}
                    disabled={!firstName.trim()}
                >
                    Continue
                </IonButton>
            </div>
        </OnboardingPageWrapper>
    );
};

export default NameInput;