import { IonButton, IonIcon, IonItem, IonLabel, useIonToast } from "@ionic/react";
import { alertCircleOutline, cameraOutline, downloadOutline, } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router";
import { UserTrickSearch } from "../../../models/user-trick-search";
import { UserTrick } from "../../../models/user-trick";
import { Media, MediaSaveOptions } from "@capacitor-community/media";
import { SubscriptionType } from "../../../models/subscriptionType";
import IDXButtonIcon from "../IDXButtonIcon/IDXButtonIcon";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

interface ContainerProps {
    list?: boolean;
    isCapacitor: boolean;
    trickId: string;
    trick: UserTrickSearch | UserTrick;
    isSubscribed: boolean;
    subscriptionLevel: string;
}

const TrickDownload: React.FC<ContainerProps> = (props: ContainerProps) => {
    const [present] = useIonToast();
    const history = useHistory();

    const presentToastAlert = async (message: string, duration: number, icon: string) => {
        await present({
            message: message,
            duration: duration,
            position: 'top',
            color: 'success',
            icon: icon,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel',
                    handler: () => {
                        console.log('Dismiss clicked');
                    },
                },
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    const presentToastDirect = async (message: string, duration: number, icon: string) => {
        await present({
            message: message,
            duration: duration,
            position: 'top',
            icon: icon,
            buttons: [
                {
                    text: 'Go',
                    handler: () => {
                        history.push("/shop");
                    },
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ],
            onDidDismiss: (e: CustomEvent) => console.log(`Dismissed with role: ${e.detail.role}`)
        });
    };

    const downloadVideoWeb = async (url: string, fileName: string) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            const urlObject = URL.createObjectURL(blob);

            // Creating a temporary link to trigger download
            const link = document.createElement('a');
            link.href = urlObject;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            // Cleanup: revoke the object URL and remove the link
            URL.revokeObjectURL(urlObject);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the video:', error);
            await presentToastAlert("An error occurred while downloading the video.", 5000, cameraOutline);
        }
    };

    const processDownloadVideo = async () => {
        if (props.isSubscribed && props.subscriptionLevel == SubscriptionType.Premium) {
            if (props.isCapacitor) {
                // use save media plugin
                let opts: MediaSaveOptions = { path: props.trick.video };
                await Media.saveVideo(opts);
                await presentToastAlert("Video saved to your camera roll!", 5000, cameraOutline);
                await Haptics.impact({ style: ImpactStyle.Light });
            } else {
                // create standard file download
                await downloadVideoWeb(props.trick.video, props.trick.trick_name + '.mp4');
            }
        } else {
            await presentToastDirect("You must have at least the tow rope pass to download a video! Check out the smoke shack ;)", 5000, alertCircleOutline);
        }
    }

    return (
        <>
            {
                props.list ? (
                    <IonItem button={true} detail={false} onClick={() => processDownloadVideo()}>
                        <IonIcon aria-hidden="true" icon={downloadOutline} slot="start"></IonIcon>
                        <IonLabel>Download</IonLabel>
                    </IonItem>
                ) : (
                    <>
                        <IonButton fill="clear" size="small" color="dark" onClick={() => processDownloadVideo()}>
                            <IDXButtonIcon size="text-2xl" icon={downloadOutline} />
                        </IonButton>
                    </>
                )
            }
        </>
    );
};

export default TrickDownload;