import axios from "axios";
import PreferencesService from "./preferences.service";

const SearchService = {
    getExploreTricks: async (numberOfTricks: number, page: number) => {
        const auth_id = await PreferencesService.getUserUid();
        const session_id = await PreferencesService.getUserSessionId();
        const user_type = await PreferencesService.getUserType();

        const randomParams = {
            number_of_tricks: numberOfTricks,
            auth_id: auth_id,
            session_id: session_id,
            user_type: user_type,
            page: page
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_USER_SERVER}/metadata/tricks/explore`, { params: randomParams })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    // note: page number starts from 0
    searchUserTrickPaginated: async (searchString: string, limit: number, page_number: number, infd_id: string) => {
        const params = {
            auth_id: infd_id,
            query: searchString,
            limit: limit,
            page: page_number
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_SEARCH_SERVER}/search/user_trick`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    },

    searchUserProfiles: async (searchString: string, limit: number, page_number: number, infd_id: string) => {
        const params = {
            auth_id: infd_id,
            query: searchString,
            limit: limit,
            page: page_number
        };

        return await axios.get(`${process.env.REACT_APP_MCTWIST_SEARCH_SERVER}/search/user_profile`, { params: params })
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.error(error);
                return null;
            });
    }
}

export default SearchService;
