import React, { useCallback, useEffect, useState, useRef, useContext } from "react";
import { useInView } from "react-intersection-observer";
import {
  isYouTubeProvider,
  MediaProviderAdapter,
  MediaPlayer,
  MediaProvider,
  Track,
  Poster,
  type MediaPlayerInstance,
} from '@vidstack/react';
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { decimalToAspectRatio } from "../../../services/utils";
import "./AutoVideoPlayer.css";
import CustomSettingsMenu from "../CustomSettingsMenu/CustomSettingsMenu";
import { useAppState } from "../../../AppListenerProvider";
import { VideoProvider } from "../VideoContextManager/VideoContextManager";
import FullscreenPlayControl from "../FullscreenPlayControl/FullscreenPlayControl";
import YoutubePlayerControl from "../YoutubePlayerControl/YoutubePlayerControl";
import CustomMuteButton from "../CustomMuteButton/CustomMuteButton";
import { AppContext } from "../../../AppStateProvider";
import { Preferences } from "@capacitor/preferences";
import PreferencesService from "../../../services/preferences.service";
import { useVolume } from "../../../VolumeProvider";

interface ContainerProps {
  src: string;
  thumbnail?: string;
  subtitles?: string;
  isCapacitor: boolean;
  threshold: number;
  isHorizontal: boolean;
  isExternal: boolean;
  isYoutube: boolean;
  isVideoRestricted: boolean;
  isScrolling: boolean;
  scrollSpeed: number;
  aspectRatio: number;
}

const playingScrollSpeedMax = 0.95; // pixels per millisecond

const AutoVideoPlayer: React.FC<ContainerProps> = (props) => {
  const { isNative, platform } = useAppState();
  const { state, dispatch } = useContext(AppContext);

  const [threshold, setThreshold] = useState<number | undefined>(undefined);
  const [playerWidth, setPlayerWidth] = useState("100%");
  const [isReady, setIsReady] = useState(false);
  const [shouldBePlaying, setShouldBePlaying] = useState(false);
  const playerRef = useRef<MediaPlayerInstance>(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const aspectRatioFraction = decimalToAspectRatio(props.aspectRatio);
  const { isMuted, setMuted } = useVolume();

  const { ref: inViewRef, inView } = useInView({
    threshold: threshold,
  });

  const calcShouldLoad = () => {
    return inView && (!props.isScrolling || props.scrollSpeed < playingScrollSpeedMax);
  }

  const loadingClass = props.isExternal ? "" : !isReady ?
    "animate-[pulse_4.5s_ease-in-out_infinite] opacity-50" :
    "opacity-100 transition-opacity duration-300";


  useEffect(() => {
    if (props.threshold !== undefined) {
      setThreshold(props.threshold);
    }
  }, [props.threshold]);

  useEffect(() => {
    // console.log("Scrolling:", props.isScrolling, "Speed:", props.scrollSpeed, "Should Load:", shouldLoad, "In View:", inView, "ID:", props.src);
    if (calcShouldLoad() && playerRef.current) {
      playerRef.current?.startLoading();
    }
  }, [inView, props.isScrolling, props.scrollSpeed]);

  useEffect(() => {
    const shouldPlay = isReady && calcShouldLoad();
    setShouldBePlaying(shouldPlay);
  }, [inView, isReady, props.isScrolling, props.scrollSpeed]);

  const updatePlayerWidth = useCallback(() => {
    const width = props.isHorizontal || props.isExternal ? "100%" : getWidthForVerticalVideo();
    setPlayerWidth(width);
  }, [props.isHorizontal, props.isExternal]);

  useEffect(() => {
    updatePlayerWidth();
    window.addEventListener("resize", updatePlayerWidth);
    return () => {
      window.removeEventListener("resize", updatePlayerWidth);
    };
  }, [updatePlayerWidth]);

  const getWidthForVerticalVideo = () => {
    const width = document.documentElement.clientWidth;
    if (width < 400) return "65%";
    if (width < 640) return "50%";
    if (width < 768) return "55%";
    if (width < 1024) return "50%";
    if (width < 1280) return "45%";
    if (width < 1536) return "40%";
    return "40%";
  }

  const handleCanPlay = () => {
    setIsReady(true);
  };

  const handleError = (event: any) => {
    console.error("Vidstack Player Error:", event.detail);
  }

  if (!props.src || threshold === undefined) {
    return null;
  }

  const handleMuteClick = () => {
    setMuted(!isMuted);
  };

  function onProviderChange(provider: MediaProviderAdapter | null) {
    if (!provider) {
      return;
    }

    if (isYouTubeProvider(provider)) {
      provider.referrerPolicy = 'no-referrer-when-downgrade';
      provider.iframe.referrerPolicy = 'no-referrer-when-downgrade';
    }
  }

  const handleMediaClick = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
  }

  return (
    <div className={"flex flex-col items-center"} ref={inViewRef}>
      <VideoProvider playerRef={playerRef}>
        <MediaPlayer
          ref={playerRef}
          src={props.src}
          poster={props.thumbnail}
          aspectRatio={aspectRatioFraction}
          playsInline
          {...(platform == "android" && { crossOrigin: "" })}
          loop
          paused={!shouldBePlaying}
          onCanPlay={handleCanPlay}
          onError={handleError}
          load="custom"
          posterLoad="eager"
          style={{ width: playerWidth }}
          onProviderChange={onProviderChange}
          className={loadingClass}
          onClick={handleMediaClick}
          controlsDelay={hasInteracted ? 1250 : 0}
          muted={isMuted}
        >
          <MediaProvider>
            {props.subtitles && (
              <Track
                src={props.subtitles}
                kind="subtitles"
                label="English"
              />
            )}
            {props.thumbnail && !props.isExternal && (
              <Poster
                className={"absolute inset-0 block h-full w-full rounded-md opacity-0 transition-opacity data-[visible]:opacity-100 [&>img]:h-full [&>img]:w-full [&>img]:object-cover"}
                src={props.thumbnail}
              />
            )}
          </MediaProvider>
          <FullscreenPlayControl hasInteracted={hasInteracted} />
          <YoutubePlayerControl src={props.src} thumbnail={props.thumbnail} isExternal={props.isExternal} isNative={isNative} />
          <DefaultVideoLayout
            smallLayoutWhen={props.isExternal ? false : true}
            icons={defaultLayoutIcons}
            slots={{
              smallLayout: {
                playButton: null,
                airPlayButton: <CustomSettingsMenu hasInteracted={hasInteracted} />,
                bufferingIndicator: null,
                muteButton: props.isExternal ? <CustomMuteButton hasInteracted={hasInteracted} mutedVolume={isMuted} handleMuteButtonClick={handleMuteClick} /> : null,
                settingsMenu: props.isExternal ? null : <CustomMuteButton hasInteracted={hasInteracted} mutedVolume={isMuted} handleMuteButtonClick={handleMuteClick} />,
              },
              largeLayout: {
                settingsMenu: <CustomSettingsMenu hasInteracted={hasInteracted} />,
                bufferingIndicator: null,
                muteButton: <CustomMuteButton hasInteracted={hasInteracted} mutedVolume={isMuted} handleMuteButtonClick={handleMuteClick} />,
              },
            }}
          />
        </MediaPlayer>
      </VideoProvider>
    </div>
  );
};

export default AutoVideoPlayer;