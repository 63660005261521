import { Geolocation } from "@capacitor/geolocation";
import { IonIcon } from "@ionic/react";
import { closeOutline, layersOutline, refreshCircle, refreshOutline, searchOutline } from "ionicons/icons";
import ReactDOM from "react-dom";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
import './WorldWeb.css';
import MapPopup from "../MapPopup/MapPopup";
import MapSearchBar from "../MapSearchBar/MapSearchBar";
import LocationService from "../../../services/location.service";
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from "../../../AppStateProvider";
import { useHistory } from "react-router";
import 'mapbox-gl/dist/mapbox-gl.css';
import { createRoot } from "react-dom/client";
import { debounce } from 'lodash';


// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

interface ContainerProps {
    lat: number;
    long: number;
    zoom: number;
    geojson: any;
    onClick?: any;
    blurred: boolean;
    flyTo: boolean;
    flyToCoords?: number[];
    isNative?: boolean;
    isSubscribed?: boolean;
    onBoundsChange?: (bounds: {
        ne_lat: number;
        ne_long: number;
        sw_lat: number;
        sw_long: number;
    }) => void;
}

const WorldWeb: React.FC<ContainerProps> = (props) => {
    const { state } = useContext(AppContext);
    const history = useHistory();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [long, setLong] = useState(props.long); // between -180 and 180
    const [lat, setLat] = useState(props.lat); // between -90 and 90
    const [zoom, setZoom] = useState(props.zoom); // Zoom levels: https://docs.mapbox.com/help/glossary/zoom-level/
    const [is3D, setIs3D] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [locationSearch, setLocationSearch] = useState(undefined);
    const [showShop, setShowShop] = useState(false);
    const loadedImages = useRef<Set<string>>(new Set());

    const previousGeojson = useRef(null);

    const userMarkerRef = useRef<mapboxgl.Marker | null>(null);

    // Add debounced bounds handler
    const handleBoundsChange = useCallback(
        debounce(() => {
            if (map.current && props.onBoundsChange) {
                const bounds = map.current.getBounds();
                props.onBoundsChange({
                    ne_lat: bounds.getNorthEast().lat,
                    ne_long: bounds.getNorthEast().lng,
                    sw_lat: bounds.getSouthWest().lat,
                    sw_long: bounds.getSouthWest().lng
                });
            }
        }, 300),
        [props.onBoundsChange]
    );

    const updateUserLocation = (coords: { longitude: number, latitude: number }) => {
        if (!map.current || !userMarkerRef.current) return;

        // Only update marker position, don't center the map
        userMarkerRef.current.setLngLat([coords.longitude, coords.latitude]);
    };

    const checkGeoLocationPermission = async () => {
        const permissions = await Geolocation.checkPermissions();
        if (permissions.location === 'denied' || permissions.location === 'granted') {
            return true;
        } else {
            return false;
        }
    }

    const getGeoLocationMobile = async () => {
        const checkLocationRes = await checkGeoLocationPermission();
        if (!checkLocationRes) {
            const res = await Geolocation.requestPermissions();
            if (res.location === 'granted') {
                return true
            } else {
                return false;
            }
        } else {
            const permissions = await Geolocation.checkPermissions();
            if (permissions.location === 'denied') {
                return false;
            }
            if (permissions.location === 'granted') {
                return true;
            }
        }
        return false;
    }

    const initializeNativeLocation = async () => {
        try {
            const accessToLocation = await getGeoLocationMobile();
            if (accessToLocation) {
                const coordinates = await Geolocation.getCurrentPosition();

                // Create user marker only once
                const uel = document.createElement('div');
                uel.classList.add('map-usermarker__border');
                uel.innerHTML = '<div class="map-usermarker__core"></div>';

                userMarkerRef.current = new mapboxgl.Marker({
                    element: uel,
                })
                    .setLngLat([coordinates.coords.longitude, coordinates.coords.latitude])
                    .addTo(map.current);

                // Set initial position
                if (!props.flyTo) {
                    map.current.jumpTo({
                        center: [coordinates.coords.longitude, coordinates.coords.latitude],
                        essential: true,
                        zoom: 9
                    });
                }

                // Watch position with optimized settings
                await Geolocation.watchPosition({
                    enableHighAccuracy: false,
                    timeout: 30000,
                    maximumAge: 10000,
                }, (position, err) => {
                    if (position) {
                        updateUserLocation(position.coords);
                    }
                });
            }
        } catch (e) {
            console.error('Error initializing native location:', e);
        }
    };

    const initializeBrowserLocation = async () => {
        try {
            navigator.geolocation.getCurrentPosition(
                async function (position) {
                    const uel = document.createElement('div');
                    uel.classList.add('map-usermarker__border');
                    uel.innerHTML = '<div class="map-usermarker__core"></div>';

                    userMarkerRef.current = new mapboxgl.Marker({
                        element: uel,
                    })
                        .setLngLat([position.coords.longitude, position.coords.latitude])
                        .addTo(map.current);

                    // Set initial position only
                    if (!props.flyTo) {
                        console.log("flying to!");
                        map.current.jumpTo({
                            center: [position.coords.longitude, position.coords.latitude],
                            essential: true,
                            zoom: 9
                        });
                    } else {
                        console.log("Not flying to!");
                    }

                    // Watch position without auto-centering
                    navigator.geolocation.watchPosition(
                        (newPosition) => {
                            updateUserLocation(newPosition.coords);
                        },
                        (error) => {
                            console.error("Error watching position:", error);
                        },
                        {
                            enableHighAccuracy: false,
                            timeout: 30000,
                            maximumAge: 10000
                        }
                    );
                },
                function (error) {
                    console.error("Error getting position:", error);
                }
            );
        } catch (e) {
            console.error('Error initializing browser location:', e);
        }
    };


    useEffect(() => {
        if (map.current) return;

        map.current = new mapboxgl.Map({
            container: mapContainer.current!,
            style: 'mapbox://styles/brendanhart/clo8icc5500bd01rfcl0b6acx',
            center: [long, lat],
            zoom: zoom,
            maxZoom: 18,
            preserveDrawingBuffer: false,
            trackResize: true,
            renderWorldCopies: false // Prevents map from repeating across the globe
        });

        // Add navigation control with minimized pitch control for better performance
        map.current.addControl(
            new mapboxgl.NavigationControl({ visualizePitch: false }),
            "top-right"
        );

        // Configure geolocation control with optimized settings
        map.current.addControl(
            new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: false, // Reduces battery usage
                    timeout: 6000
                },
                trackUserLocation: true,
                showUserHeading: false // Disable heading indicator for better performance
            })
        );

        // Initialize map with optimized image loading
        map.current.on('load', () => {
            initializeMap();
            // Clean up unused resources after initial load
            handleBoundsChange();
        });


        // Add bounds change listeners
        map.current.on('moveend', handleBoundsChange);
        map.current.on('zoomend', handleBoundsChange);

        return () => {
            handleBoundsChange.cancel();
            map.current?.off('moveend', handleBoundsChange);
            map.current?.off('zoomend', handleBoundsChange);
            map.current?.remove();
        };
    }, []);

    // Add this new useEffect hook
    useEffect(() => {
        if (map.current) {
            const resizeMap = () => {
                map.current.resize();
            };

            // Resize immediately
            resizeMap();

            // Resize after a short delay
            const timeoutId = setTimeout(resizeMap, 100);

            // Resize on window resize
            window.addEventListener('resize', resizeMap);

            // Clean up
            return () => {
                clearTimeout(timeoutId);
                window.removeEventListener('resize', resizeMap);
            };
        }
    }, [map.current]); // Only re-run if map.current changes

    const initializeMap = async () => {
        if (!map.current) return;

        map.current.resize();

        // add the DEM source as a terrain layer with exaggerated height
        map.current.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

        // ADD CLOWN SHIT
        map.current.loadImage(
            'https://corked.blob.core.windows.net/logos/clown.png',
            (error: any, image: any) => {
                if (error) throw error;

                // Add the image to the map style.
                map.current.addImage('clown', image);

                // Add a data source containing one point feature.
                map.current.addSource('clown-towns', {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': [7.672850, 46.729440]
                                }
                            }
                        ]
                    }
                });

                // Add a layer to use the image to represent the data.
                map.current.addLayer({
                    'id': 'clowning',
                    'type': 'symbol',
                    'source': 'clown-towns', // reference the data source
                    'layout': {
                        'icon-image': 'clown', // reference the image
                        "icon-size": [
                            'interpolate',
                            // Set the exponential rate of change to 1.5
                            ['linear'],
                            ['zoom'],
                            // When zoom is 10, icon will be 50% size.
                            10,
                            0.01,
                            // When zoom is 22, icon will be 10% size.
                            22,
                            2
                        ], // Use the 'iconSize' property to specify the icon size for each feature
                    }
                });
            }
        );

        if (!props.isNative) {
            map.current.addSource('idx-nation', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [
                            [
                                [-180, -90],
                                [180, -90],
                                [180, -60],
                                [-180, -60],
                                [-180, -90]
                            ]
                        ]
                    }
                }
            });

            map.current.loadImage(
                'https://corked.blob.core.windows.net/logos/ID_logowithname-04.png',
                (err: any, image: any) => {
                    // Throw an error if something goes wrong.
                    if (err) throw err;

                    // Add the image to the map style.
                    map.current.addImage('idx-logo', image);

                    // Create a new layer and style it using `fill-pattern`.
                    map.current.addLayer({
                        'id': 'idx-layer',
                        'type': 'fill',
                        'source': 'idx-nation',
                        'paint': {
                            'fill-pattern': 'idx-logo'
                        }
                    });
                }
            );
        }

        if (props.isNative) {
            await initializeNativeLocation();
        } else {
            await initializeBrowserLocation();
        }
    };

    // Add this helper function at the top
    const areGeoJsonFeaturesEqual = (features1: any[], features2: any[]) => {
        if (features1.length !== features2.length) return false;

        return features1.every((feature1, index) => {
            const feature2 = features2[index];
            return (
                feature1.geometry.coordinates[0] === feature2.geometry.coordinates[0] &&
                feature1.geometry.coordinates[1] === feature2.geometry.coordinates[1] &&
                feature1.properties.id === feature2.properties.id
            );
        });
    };

    const updateMapSource = () => {
        if (!map.current || !props.geojson || !props.geojson.features || props.geojson.features.length === 0) return;

        // Check if the data has actually changed
        if (previousGeojson.current &&
            areGeoJsonFeaturesEqual(previousGeojson.current.features, props.geojson.features)) {
            return; // Skip update if data hasn't changed
        }

        const sourceId = 'tricks';

        // Create a function to add all layers
        const addLayers = () => {
            // Add clusters layer
            map.current.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'tricks',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#51bbd6',
                        10,
                        '#f1f075',
                        30,
                        '#f28cb1',
                        50,
                        '#5861AC'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        15,
                        10,
                        25,
                        30,
                        35,
                        50,
                        45
                    ]
                }
            });

            map.current.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'tricks',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12
                }
            });

            map.current.addLayer({
                id: 'unclustered-point',
                type: 'symbol',
                source: 'tricks',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': ['get', 'image'],
                    "icon-size": [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        0.05,
                        22,
                        0.3
                    ],
                    'icon-allow-overlap': true,
                    'icon-padding': 10,
                }
            });
        };

        if (map.current.getSource(sourceId)) {
            // Update existing source
            const source = map.current.getSource(sourceId) as mapboxgl.GeoJSONSource;
            source.setData(props.geojson);
        } else {
            // Initial source setup
            map.current.addSource(sourceId, {
                type: 'geojson',
                data: props.geojson,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
                generateId: true,
                maxzoom: 18
            });

            // Load all images before adding layers
            const imageLoadPromises = props.geojson.features.map((feature: GeoJSON.Feature) => {
                const image = feature.properties?.image as string;
                if (image && !loadedImages.current.has(image)) {
                    return new Promise((resolve) => {
                        map.current!.loadImage(image, (error: any, loadedImage: any) => {
                            if (error) {
                                console.warn(`Failed to load image ${image}:`, error);
                                resolve(null);
                                return;
                            }
                            if (!map.current!.hasImage(image)) {
                                try {
                                    map.current!.addImage(image, loadedImage);
                                    loadedImages.current.add(image);
                                } catch (e) {
                                    console.warn('Error adding image:', e);
                                }
                            }
                            resolve(image);
                        });
                    });
                }
                return Promise.resolve(null);
            });

            // Handle missing images
            const handleMissingImage = (e: any) => {
                const id = e.id;
                map.current!.loadImage(id, (error: any, loadedImage: any) => {
                    if (error) return;
                    if (!map.current!.hasImage(id)) {
                        map.current!.addImage(id, loadedImage);
                        loadedImages.current.add(id);
                    }
                });
            };

            map.current.on('styleimagemissing', handleMissingImage);

            // Wait for images to load then add layers and event listeners
            Promise.all(imageLoadPromises)
                .then(() => {
                    addLayers();

                    // Add event listeners
                    map.current.on('click', 'clusters', (e: any) => {
                        const features = map.current.queryRenderedFeatures(e.point, {
                            layers: ['clusters']
                        });
                        const clusterId = features[0].properties.cluster_id;
                        map.current.getSource('tricks').getClusterExpansionZoom(
                            clusterId,
                            (err: any, zoom: any) => {
                                if (err) return;
                                map.current.easeTo({
                                    center: features[0].geometry.coordinates,
                                    zoom: zoom
                                });
                            }
                        );
                    });

                    map.current!.on('click', 'unclustered-point', (e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] }) => {
                        if (!e.features || e.features.length === 0) return;

                        const coordinates = e.features[0].geometry.type === 'Point'
                            ? (e.features[0].geometry as GeoJSON.Point).coordinates.slice() as [number, number]
                            : e.lngLat.toArray();

                        const flyToClick = () => {
                            map.current!.jumpTo({
                                center: coordinates,
                                essential: true,
                            });
                        };

                        flyToClick();

                        const trick = JSON.parse(e.features[0].properties!.trick as string);

                        const popupNode = document.createElement('div');
                        const root = createRoot(popupNode);
                        root.render(
                            <MapPopup
                                trick={trick}
                                onClick={onTrickClick}
                                onClickFlyTo={flyToClick}
                                loggedInId={state.user.id}
                            />
                        );

                        const trickPopup = new mapboxgl.Popup({
                            closeOnClick: true,
                            closeButton: false,
                        })
                            .setLngLat(coordinates)
                            .setDOMContent(popupNode)
                            .addTo(map.current!);

                        trickPopup.on('close', () => {
                            root.unmount();
                        });
                    });

                    map.current.on('mouseenter', 'clusters', () => {
                        map.current.getCanvas().style.cursor = 'pointer';
                    });
                    map.current.on('mouseleave', 'clusters', () => {
                        map.current.getCanvas().style.cursor = '';
                    });
                });
        }

        // Store the current geojson for future comparison
        previousGeojson.current = props.geojson;
    };

    useEffect(() => {
        if (!map.current || !props.geojson) return;

        updateMapSource();
    }, [props.geojson]);

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        map.current.on('move', () => {
            setLong(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    }, []);

    useEffect(() => {
        setShowShop(!props.isSubscribed);
    }, [props.isSubscribed]);

    useEffect(() => {
        if (!map.current || !props.flyTo || !props.flyToCoords) return;

        map.current.resize();
        map.current.jumpTo({
            center: props.flyToCoords,
            essential: true,
            zoom: 9
        });
    }, [props.flyTo, props.flyToCoords]);

    const onTrickClick = (trickId: string, userId: string) => {
        map.current!.fire('closeAllPopups');
        props.onClick(trickId, userId);
    };

    const onToggle3D = () => {
        if (is3D) {
            setIs3D(false);
            map.current!.easeTo({ pitch: 0, bearing: 0 });
        } else {
            setIs3D(true);
            map.current!.easeTo({ pitch: 70, bearing: -30 });
        }
        map.current!.resize();
    };

    const onToggleSearch = () => {
        setShowSearch(!showSearch);
    };

    const goUpgrade = () => {
        history.push('/shop');
    };

    const resizeMap = () => {
        if (!map.current) return;
        map.current!.resize();
    };

    const onLocationSearch = async (query: string) => {
        const res = await LocationService.getLocationFromTextPlacesAPI(query);
        if (res) {
            const searched_location = res.result.geometry.location;
            map.current!.jumpTo({
                center: [parseFloat(searched_location.lng), parseFloat(searched_location.lat)],
                essential: true,
                zoom: 12
            });
        }
        setShowSearch(false);
    };

    return (
        <div className="world-web-container" style={{ height: '100%', width: '100%' }}>
            {props.blurred ? <></> :
                <>
                    <div className="z-100 fixed top-44 right-1" style={{ zIndex: 100 }}>
                        <div
                            className="w-[40px] h-[40px] rounded-full flex justify-center items-center circle-button text-sm text-black"
                            onClick={onToggle3D}>
                            <div>
                                {is3D ? "2D" : "3D"}
                            </div>
                        </div>
                    </div>
                    <div className="z-100 fixed top-56 right-1" style={{ zIndex: 100 }}>
                        <div
                            className="w-[40px] h-[40px] rounded-full flex justify-center items-center circle-button text-sm text-black"
                            onClick={onToggleSearch}>
                            <IonIcon slot="icon-only" icon={!showSearch ? searchOutline : closeOutline}></IonIcon>
                        </div>
                    </div>
                    {
                        (showShop) &&
                        <div className="z-100 fixed top-[292px] right-1" style={{ zIndex: 100 }}>
                            <div
                                className="w-[40px] h-[40px] rounded-full flex justify-center items-center circle-button text-sm text-black"
                                onClick={goUpgrade}>
                                <IonIcon slot="icon-only" icon={layersOutline}></IonIcon>
                            </div>
                        </div>
                    }
                    {
                        <div className="z-100 fixed bottom-4 right-1" style={{ zIndex: 100 }}>
                            <div
                                className="w-[40px] h-[40px] rounded-full flex justify-center items-center circle-button text-sm text-black"
                                onClick={resizeMap}>
                                <IonIcon slot="icon-only" icon={refreshOutline}></IonIcon>
                            </div>
                        </div>
                    }
                    <div className="z-100 fixed bottom-24" style={{ zIndex: 100 }}>
                        <div className="grid w-screen place-items-center">
                            <MapSearchBar
                                placeholder='Search for a location'
                                webPlaceholder='Search for a location'
                                mobilePlaceholder='Search location'
                                onSubmit={onLocationSearch}
                                showOnMap={showSearch}
                            />
                        </div>
                    </div>
                </>
            }
            <div ref={mapContainer} className={`map-container ${props.blurred ? "blurred_map" : ""}`} />
        </div>
    );
};

export default WorldWeb;
