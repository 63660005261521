import React, { useRef } from 'react';
import { IonSearchbar } from "@ionic/react";
import { Keyboard } from '@capacitor/keyboard';

interface EclipticSearchbarProps {
    placeholder?: string;
    animated?: boolean;
    debounce?: number;
    onIonCancel?: () => void;
    onFocus?: () => void;
    onIonInput?: (e: any) => void;
    inputmode?: "search" | "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal";
    showCancelButton?: "never" | "focus" | "always";
    showClearButton?: "never" | "focus" | "always";
    value?: string;
    style?: any;
    id?: string;
    color?: string;
    className?: string;
}

const EclipticSearchbar: React.FC<EclipticSearchbarProps> = ({ 
    placeholder, 
    animated, 
    debounce, 
    onIonCancel, 
    onFocus, 
    onIonInput, 
    inputmode = "search", 
    showCancelButton, 
    showClearButton, 
    value, 
    style, 
    id, 
    color, 
    className 
}) => {

    const searchBarRef = useRef(null);

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            // Hide keyboard
            Keyboard.hide();
            // Remove focus from the search input
            searchBarRef.current?.getInputElement().then((input: any) => {
                input.blur();
            });
        }
    };


    return (
        <IonSearchbar
            ref={searchBarRef}
            {...placeholder !== undefined && { placeholder }}
            {...animated !== undefined && { animated }}
            {...debounce !== undefined && { debounce }}
            {...onIonCancel !== undefined && { onIonCancel }}
            {...onFocus !== undefined && { onFocus }}
            {...onIonInput !== undefined && { onIonInput }}
            {...inputmode !== undefined && { inputmode }}
            {...showCancelButton !== undefined && { showCancelButton }}
            {...showClearButton !== undefined && { showClearButton }}
            {...value !== undefined && { value }}
            {...style !== undefined && { style }}
            {...id !== undefined && { id }}
            {...color !== undefined && { color }}
            {...className !== undefined && { className }}
            onKeyDown={handleKeyDown}
        />
    )
};

export default EclipticSearchbar;