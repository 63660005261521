import React, { useRef, useState, useEffect } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
    IonButton,
    IonSpinner,
} from "@ionic/react";
import { gridOutline, listOutline } from "ionicons/icons";
import { useAuth } from '../../../AuthProvider';
import { useQuery } from '@tanstack/react-query';
import { isNative } from 'lodash';
import { BrandEvent } from '../../../models/brandEvent';
import BrandsService from '../../../services/brand.service';
import LoginButton from '../../ComponentsLogin/LoginButton/LoginButton';
import IDXButtonIcon from '../../ComponentsUI/IDXButtonIcon/IDXButtonIcon';
import EventCard from '../EventCard/EventCard';
import EventList from '../EventList/EventList';
import EclipticSearchbar from '../../ComponentsUI/EclipticSearchbar/EclipticSearchbar';

interface EventListContainerProps { }

const EventListContainer: React.FC<EventListContainerProps> = () => {
    const { isAuthenticated } = useAuth();
    const [eventsFilter, setEventsFilter] = useState<string>("");
    const [eventsGrid, setEventsGrid] = useState<boolean>(true);
    const [columnCount, setColumnCount] = useState(1);
    const parentRef = useRef<HTMLDivElement>(null);

    const { data: events = [], isLoading: eventsLoading } = useQuery({
        queryKey: ['events'],
        queryFn: BrandsService.getAllEvents,
    });

    useEffect(() => {
        const updateColumnCount = () => {
            const width = window.innerWidth;
            if (width >= 1280) setColumnCount(4); // xl
            else if (width >= 768) setColumnCount(2); // md
            else if (width >= 640) setColumnCount(2); // sm
            else setColumnCount(1); // xs
        };

        updateColumnCount();
        window.addEventListener('resize', updateColumnCount);
        return () => window.removeEventListener('resize', updateColumnCount);
    }, []);

    const filteredEvents = events.filter((event: BrandEvent) =>
        event.name.toLowerCase().includes(eventsFilter.toLowerCase())
    );

    const rowVirtualizer = useVirtualizer({
        count: eventsGrid ? Math.ceil(filteredEvents.length / columnCount) : filteredEvents.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => eventsGrid ? 600 : 200,
        overscan: 2,
    });

    const columnVirtualizer = useVirtualizer({
        horizontal: true,
        count: columnCount,
        getScrollElement: () => parentRef.current,
        estimateSize: () => parentRef.current ? parentRef.current.offsetWidth / columnCount : 300,
        overscan: 2,
    });

    const toggleEventsGrid = () => {
        setEventsGrid(!eventsGrid);
    }

    useEffect(() => {
        if (!eventsLoading) {
            window.prerenderReady = true;
        }
    }, [eventsLoading]);

    return (
        <>
            <div className="flex flex-row items-center mx-4 mt-2 justify-end">
                <div className="flex-1">
                    <EclipticSearchbar
                        onIonInput={(e) => setEventsFilter(e.detail.value)}
                        showClearButton="focus"
                        showCancelButton="focus"
                        style={{ padding: 0 }}
                        id="searchText"
                        debounce={0}
                        animated={true}
                    />
                </div>
                <div>
                    <IonButton size="small" fill="clear" color="theme-secondary" onClick={toggleEventsGrid}>
                        <IDXButtonIcon size="text-2xl" icon={eventsGrid ? listOutline : gridOutline} />
                    </IonButton>
                </div>
            </div>
            {!isAuthenticated ? (
                <div className="m-4">
                    <LoginButton
                        title="Upload to Ecliptic and Enter Events to Get Recognized and See the Biskness"
                        upload={false}
                        profile={true}
                    />
                </div>
            ) : eventsLoading ? (
                <div className="m-4 text-center flex flex-row align-bottom">
                    <div>{"Retrieving events for you "}</div>
                    <IonSpinner name="dots" />
                </div>
            ) : filteredEvents.length === 0 ? (
                <div className="m-4 text-center">No events found</div>
            ) : (
                <div
                    ref={parentRef}
                    style={{
                        height: '80vh',
                        overflow: 'auto',
                    }}
                >
                    <div
                        style={{
                            height: `${rowVirtualizer.getTotalSize()}px`,
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                            <div
                                key={virtualRow.key}
                                data-index={virtualRow.index}
                                ref={rowVirtualizer.measureElement}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    transform: `translateY(${virtualRow.start}px)`,
                                    display: eventsGrid ? 'flex' : 'block',
                                }}
                            >
                                {eventsGrid ? (
                                    columnVirtualizer.getVirtualItems().map((virtualColumn) => {
                                        const eventIndex = virtualRow.index * columnCount + virtualColumn.index;
                                        const event = filteredEvents[eventIndex];
                                        if (!event) return null;
                                        return (
                                            <div
                                                key={virtualColumn.key}
                                                style={{
                                                    width: `${100 / columnCount}%`,
                                                    padding: '8px',
                                                }}
                                            >
                                                <EventCard brandEvent={event} />
                                            </div>
                                        );
                                    })
                                ) : (
                                    <EventList brandEvent={filteredEvents[virtualRow.index]} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default EventListContainer;