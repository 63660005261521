import { IonAvatar, IonContent, IonItem, IonPage, IonSpinner } from "@ionic/react";
import React, { useEffect } from "react";
import { UserProfileSearch } from "../../../models/user-profile-search";
import SearchService from "../../../services/search.service";
import UserService from "../../../services/user.service";
import IDXModalHeader from "../../ComponentsUI/IDXModalHeader/IDXModalHeader";

interface ContainerProps {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void,
    profileSearchValue: string,
    onClickResult: (user: UserProfileSearch) => void;
}

const ProfileSearch: React.FC<ContainerProps> = ({ onDismiss, profileSearchValue, onClickResult }) => {
    const [profileSearchResults, setProfileSearchResults] = React.useState<UserProfileSearch[]>([]);
    const [profileToProfilePic, setProfileToProfilePic] = React.useState(new Map<string, string>());
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        const getProfileResults = async () => {
            setLoading(true);
            setProfileSearchResults([]);
            console.log("Searching for: " + profileSearchValue);
            // TODO: Obviously fix because of shit
            const res: UserProfileSearch[] = await SearchService.searchUserProfiles(profileSearchValue, 20, 0, "1");
            const userIdToProfilePic = new Map<string, string>();
            for (let i = 0; i < res.length; i++) {
                const userProfilePic = await UserService.getUserProfilePic(res[i].id);
                userIdToProfilePic.set(res[i].id, userProfilePic['url']);
            }

            setProfileToProfilePic(userIdToProfilePic);
            await setProfileSearchResults(res);
            setLoading(false);
        }

        getProfileResults();
    }, [profileSearchValue]);

    const onClickPerson = (user: UserProfileSearch) => {
        onClickResult(user);
        onDismiss();
    }

    return (
        <IonPage>
            <IDXModalHeader leftButtonAction={() => onDismiss(null, 'cancel')}
                titleText={'Profile Search'}
                rightButtonText={'Close'}
                rightButtonAction={() => onDismiss(null, 'cancel')} />
            <IonContent>
                {loading ?
                    <IonItem className="align-bottom space-x-4">
                        <div>Searching</div>
                        <IonSpinner name="dots"></IonSpinner>
                    </IonItem> :
                    profileSearchResults && profileSearchResults.length > 0 ?
                        profileSearchResults.map((user, index) => (
                            <IonItem key={index} className="items-center" onClick={() => onClickPerson(user)}>
                                <IonAvatar slot="start" className="ml-2">
                                    <img className="shrink w-8 h-8 rounded-full"
                                        src={profileToProfilePic.has(user.id) ? ((profileToProfilePic.get(user.id) === undefined || profileToProfilePic.get(user.id) === null) ? '/assets/photos/defaultProPic.png' : profileToProfilePic.get(user.id)) : '/assets/photos/defaultProPic.png'}></img>
                                </IonAvatar>
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <div className="text-xl">{user?.username}</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="text-base">{user?.name}</div>
                                    </div>
                                </div>
                            </IonItem>)) :
                        <IonItem className="text-xl justify-center text-center">No users found searching
                            for {profileSearchValue}</IonItem>
                }
            </IonContent>
        </IonPage>
    );
};

export default ProfileSearch;