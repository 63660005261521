import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CommentView } from "../../../models/commentView";
import UserService from "../../../services/user.service";
import { filterTrickCommentsForBlockedIds, splitString } from "../../../services/utils";
import { AppContext } from "../../../AppStateProvider";
import { IonButton, IonIcon, IonModal, IonSpinner, IonTextarea } from "@ionic/react";
import UsernameDisplay from "../../ComponentsUI/Username/UsernameDisplay";
import ReportComment from "../ReportComment/ReportComment";
import { arrowUpOutline, heart, heartOutline } from "ionicons/icons";
import { LikeView } from "../../../models/likeView";
import ViewLikesContent from "../../ComponentsUI/ViewLikesContent/ViewLikesContent";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import { Haptics, ImpactStyle } from "@capacitor/haptics";


interface ContainerProps {
    trickId: string;
    publicId: string;
    isAuthed: boolean;
    onClickPerson: (username: string) => void;
    onSetTrickCommentCount?: (count: number) => void;
    displayCommentLikesForModal?: boolean;
    handleClickViewCommentLikes?: () => void;
    viewCommentLikes?: boolean;
}

const CommentDisplay: React.FC<ContainerProps> = ({
    trickId,
    publicId,
    isAuthed,
    onClickPerson,
    onSetTrickCommentCount,
    displayCommentLikesForModal = false,
    handleClickViewCommentLikes,
    viewCommentLikes = false
}) => {
    const [commentHtmlMap, setCommentHtmlMap] = useState<Map<string, JSX.Element>>(new Map<string, JSX.Element>());


    const { state } = useContext(AppContext);
    const [topLevelTrickComments, setTopLevelTrickComments] = React.useState<CommentView[]>([]);
    const [loading, setLoading] = React.useState(false);

    const [userComment, setUserComment] = React.useState("");
    const userCommentInputRef = useRef<HTMLIonTextareaElement>(null);

    const modalMap = useRef(new Map<string, React.RefObject<HTMLIonModalElement>>());

    const [totalCommentCount, setTotalCommentCount] = React.useState<number>(0);

    const commentToLikesMap = new Map<string, LikeView[]>();
    const [commentLikesToView, setCommentLikesToView] = React.useState<LikeView[]>([]);

    const [editingComment, setEditingComment] = React.useState<boolean>(false);
    const [commentEditing, setCommentEditing] = React.useState<CommentView>(undefined);
    const [masterCommentForEditing, setMasterCommentForEditing] = React.useState<CommentView>(undefined);

    const [replyingComment, setReplyingComment] = React.useState<boolean>(false);
    const [commentReplying, setCommentReplying] = React.useState<CommentView>(undefined);
    const [masterCommentForReplying, setMasterCommentForReplying] = React.useState<CommentView>(undefined);

    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const [commentToReplyMap, setCommentToReplyMap] = useState<Map<string, CommentView[]>>(new Map<string, CommentView[]>());
    const [commentToViewReplyMap, setCommentToViewReplyMap] = useState<Map<string, boolean>>(new Map<string, boolean>());

    const addTaggedUserToComments = (comment: string) => {
        const parsedComment = splitString(comment, /(\@[A-Za-z0-9_.-]+)/);
        return (
            <>
                {parsedComment.map((phrase, index) => {
                    if (phrase.at(0) === '@') {
                        return (
                            <span
                                className="text-indigo-300 cursor-pointer"
                                onClick={async () => {
                                    onClickPerson(phrase.substring(1));
                                }}
                                key={index}
                            >
                                {phrase}
                            </span>
                        );
                    } else {
                        return <span key={index}>{phrase}</span>;
                    }
                })}
            </>
        );
    }

    const addCommentToMap = useCallback((comment: CommentView) => {
        setCommentHtmlMap((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(
                comment.comment_id,
                <div key={comment.comment_id} className="text-balance">
                    {addTaggedUserToComments(comment.content)}
                </div>
            );
            return newMap;
        });
    }, []);

    const getTrickComments = async (trick_id: string, isAuthed: boolean, publicId: string) => {
        // Only used for initial get
        setLoading(true);
        const response: CommentView[] = isAuthed && publicId ? await UserService.getTrickComments(trick_id, publicId) : await UserService.getTrickComments(trick_id, undefined);
        if (response) {
            const filteredComments = filterTrickCommentsForBlockedIds(response, state.blockedUsers, state.blockingUsers);
            if (!displayCommentLikesForModal) {
                for (const filteredComment of filteredComments) {
                    modalMap.current.set(filteredComment.comment_id, React.createRef<HTMLIonModalElement>());
                }
            }

            const newCommentToReplyMap = new Map<string, CommentView[]>();
            const newCommentToViewReplyMap = new Map<string, boolean>();

            for (const filteredComment of filteredComments) {
                addCommentToMap(filteredComment);
                newCommentToReplyMap.set(filteredComment.comment_id, []);
                newCommentToViewReplyMap.set(filteredComment.comment_id, false);
            }

            setCommentToReplyMap(newCommentToReplyMap);
            setCommentToViewReplyMap(newCommentToViewReplyMap);

            setTopLevelTrickComments(filteredComments);

            if (onSetTrickCommentCount) {
                const count: number = await UserService.getCommentCountForTrick(trick_id);
                setTotalCommentCount(count);
                onSetTrickCommentCount(count);
            }
        }

        setLoading(false);
    }

    const reloadTrickComments = async (trick_id: string, newTrickComments: CommentView[]) => {
        const response: CommentView[] = await UserService.getTrickComments(trick_id, publicId);
        if (response) {
            const filteredComments = filterTrickCommentsForBlockedIds(response, state.blockedUsers, state.blockingUsers);
            if (!displayCommentLikesForModal) {
                for (const filteredComment of filteredComments) {
                    modalMap.current.set(filteredComment.comment_id, React.createRef<HTMLIonModalElement>());
                }
            }

            const updatedComments = newTrickComments.map((comment) => {
                // Find the matching comment in the filtered comments
                const matchedComment = filteredComments.find(
                    (filteredComment) => filteredComment.comment_id === comment.comment_id
                );

                // If a matching comment is found, update the comment's values
                if (matchedComment) {
                    return {
                        ...comment,
                        like_count: matchedComment.like_count,
                        reply_count: matchedComment.reply_count,
                        interaction_count: matchedComment.interaction_count,
                        created_at: matchedComment.created_at,
                        content: matchedComment.content,
                        action_user_id: matchedComment.action_user_id,
                        username: matchedComment.username,
                        profile_pic: matchedComment.profile_pic,
                        user_liked: matchedComment.user_liked,
                    };
                }

                // If no matching comment is found, return the original comment
                return comment;
            });

            // Update the state with the updated comments
            setTopLevelTrickComments(updatedComments);

            const newCommentToViewReplyMap = new Map<string, boolean>(commentToViewReplyMap);

            for (const filteredComment of filteredComments) {
                addCommentToMap(filteredComment);
                if (newCommentToViewReplyMap.has(filteredComment.comment_id)) {
                    newCommentToViewReplyMap.set(filteredComment.comment_id, newCommentToViewReplyMap.get(filteredComment.comment_id));
                } else {
                    newCommentToViewReplyMap.set(filteredComment.comment_id, false);
                }
            }

            setCommentToViewReplyMap(newCommentToViewReplyMap);

            if (onSetTrickCommentCount) {
                const count: number = await UserService.getCommentCountForTrick(trick_id);
                setTotalCommentCount(count);
                onSetTrickCommentCount(count);
            }
        }
    }

    const handleFocusOnComments = async () => {
        if (userCommentInputRef.current) {
            await userCommentInputRef.current?.setFocus();
        }
    }

    const onUserCommentValueChange = (userCommentValue: string) => {
        setUserComment(userCommentValue);
    }

    const handleCommentSubmit = async () => {
        setSubmitting(true);
        if (editingComment) {
            if (userComment.trim() === '') {
                setSubmitting(false);
                return;
            }

            if (userComment === commentEditing.content) {
                setEditingComment(false);
                setUserComment("");
                setCommentEditing(undefined);
                setMasterCommentForReplying(undefined);
                setSubmitting(false);
                return;
            }

            const newTrickComments = topLevelTrickComments.map((comment) => {
                if (comment.comment_id === commentEditing.comment_id) {
                    const newComment = {
                        ...comment,
                        content: userComment,
                    };
                    addCommentToMap(newComment);
                    return newComment;
                }
                return comment;
            });

            const newTrickCommentsReply = new Map(commentToReplyMap);

            if (newTrickCommentsReply.has(masterCommentForEditing.comment_id)) {
                newTrickCommentsReply.set(masterCommentForEditing.comment_id, newTrickCommentsReply.get(masterCommentForEditing.comment_id).map((reply) => {
                    if (reply.comment_id === commentEditing.comment_id) {
                        const newComment = {
                            ...reply,
                            content: userComment,
                        };
                        addCommentToMap(newComment);
                        return newComment;
                    }
                    return reply;
                }));
            }

            setCommentToReplyMap(newTrickCommentsReply);
            setTopLevelTrickComments(newTrickComments);

            setEditingComment(false);

            await Haptics.impact({ style: ImpactStyle.Light });

            await UserService.editComment(commentEditing.comment_id, publicId, userComment);
            setUserComment("");
            setCommentEditing(undefined);
            setMasterCommentForEditing(undefined);
            await reloadTrickComments(trickId, newTrickComments);
        } else if (replyingComment) {
            // GET PARENT COMMENT ID
            if (userComment.trim() === '') {
                setSubmitting(false);
                return;
            }

            const commentId = uuidv4();
            const commentObj: CommentView = {
                comment_id: commentId,
                like_count: 0,
                reply_count: 0,
                interaction_count: 0,
                created_at: new Date().toISOString(),
                content: userComment,
                action_user_id: publicId,
                parent_action_id: commentReplying.comment_id,
                username: state.user.username,
                profile_pic: state.user.profile.profile_pic,
                user_liked: false,
                depth: 0,
                path: "",
            }

            addCommentToMap(commentObj);

            const newTrickComments = topLevelTrickComments.map((comment) => {
                if (comment.comment_id === commentReplying.comment_id) {
                    return {
                        ...comment,
                        reply_count: comment.reply_count + 1,
                    };
                }
                return comment;
            });

            // insert the reply where it belongs in the map of replies
            const newCommentToReplyMap = new Map(commentToReplyMap);
            const replies = newCommentToReplyMap.has(masterCommentForReplying.comment_id) ? newCommentToReplyMap.get(masterCommentForReplying.comment_id) : [];
            replies.push(commentObj);
            newCommentToReplyMap.set(masterCommentForReplying.comment_id, replies);
            setCommentToReplyMap(newCommentToReplyMap);

            setTopLevelTrickComments(newTrickComments);
            if (onSetTrickCommentCount) {
                onSetTrickCommentCount(totalCommentCount + 1);
            }

            await Haptics.impact({ style: ImpactStyle.Light });
            await UserService.replyComment(trickId, publicId, userComment, commentId, commentReplying.comment_id);
            setUserComment("");
            await reloadTrickComments(trickId, newTrickComments);
            setReplyingComment(false);
            setCommentReplying(undefined);
            setMasterCommentForReplying(undefined);
            await handleGetNewCommentReplies(commentReplying.comment_id);
            if (commentToViewReplyMap.has(commentReplying.comment_id)) {
                setCommentToViewReplyMap(new Map(commentToViewReplyMap.set(commentReplying.comment_id, true)));
            }
        } else {
            if (userComment.trim() === '') {
                setSubmitting(false);
                return;
            }

            const commentId = uuidv4();
            const commentObj: CommentView = {
                comment_id: commentId,
                like_count: 0,
                reply_count: 0,
                interaction_count: 0,
                created_at: new Date().toISOString(),
                content: userComment,
                action_user_id: publicId,
                parent_action_id: undefined,
                username: state.user.username,
                profile_pic: state.user.profile.profile_pic,
                user_liked: false,
                depth: 0,
                path: "",
            }

            addCommentToMap(commentObj);

            const newTrickComments = [commentObj, ...topLevelTrickComments]
            setTopLevelTrickComments((prevTrickComments) => [commentObj, ...prevTrickComments]);

            setUserComment("");
            if (onSetTrickCommentCount) {
                onSetTrickCommentCount(totalCommentCount + 1);
            }
            await Haptics.impact({ style: ImpactStyle.Light });

            await UserService.commentTrick(trickId, publicId, commentObj.content, commentId);
            await reloadTrickComments(trickId, newTrickComments);
        }

        setSubmitting(false);
    }

    const handleLikeComment = async (commentId: string, like: boolean) => {
        const newTrickComments = topLevelTrickComments.map((comment) => {
            let count = comment.like_count;
            if (like) {
                count++;
            } else {
                count--;
            }

            if (comment.comment_id === commentId) {
                return {
                    ...comment,
                    like_count: count,
                    user_liked: like,
                };
            }

            return comment;
        });

        setTopLevelTrickComments(newTrickComments);
        await Haptics.impact({ style: ImpactStyle.Light });
        await UserService.likeComment(trickId, publicId, commentId, like);
        await handleGetCommentLikes(commentId);
        await reloadTrickComments(trickId, newTrickComments);
    }

    const handleLikeReplyComment = async (parentCommentId: string, commentId: string, like: boolean) => {
        const newCommentToReplyMap = new Map(commentToReplyMap);
        const replies = newCommentToReplyMap.get(parentCommentId);

        const newReplies = replies.map((reply) => {
            let count = reply.like_count;
            if (like) {
                count++;
            } else {
                count--;
            }

            if (reply.comment_id === commentId) {
                return {
                    ...reply,
                    like_count: count,
                    user_liked: like,
                };
            }

            return reply;
        });

        newCommentToReplyMap.set(parentCommentId, newReplies);
        setCommentToReplyMap(newCommentToReplyMap);

        await UserService.likeComment(trickId, publicId, commentId, like);
        await handleGetCommentLikes(commentId);
    }

    const handleGetCommentLikes = async (commentId: string) => {
        const likes = await UserService.getCommentLikes(commentId);
        commentToLikesMap.set(commentId, likes);
        return likes;
    }

    const handleViewCommentLikes = async (commentId: string) => {
        setCommentLikesToView([]);
        const likes = commentToLikesMap.get(commentId);
        if (likes) {
            setCommentLikesToView(likes);
        } else {
            setCommentLikesToView(await handleGetCommentLikes(commentId));
        }
    }

    const handleViewCommentReplies = async (commentId: string) => {
        const viewComments = commentToViewReplyMap.get(commentId);

        if (!viewComments) {
            await handleGetNewCommentReplies(commentId)
        }

        const newCommentToViewReplyMap = new Map(commentToViewReplyMap);
        newCommentToViewReplyMap.set(commentId, !viewComments);
        setCommentToViewReplyMap(newCommentToViewReplyMap);
    }

    const handleGetNewCommentReplies = async (commentId: string): Promise<CommentView[]> => {
        const replies = isAuthed && publicId ? await UserService.getCommentReplies(commentId, publicId) : await UserService.getTrickComments(commentId, undefined);

        for (const reply of replies) {
            addCommentToMap(reply);
        }

        // Create a new map instance
        const newCommentToReplyMap = new Map(commentToReplyMap);

        // Update the new map with the replies
        newCommentToReplyMap.set(commentId, replies);

        // Set the state with the updated map
        setCommentToReplyMap(newCommentToReplyMap);

        return replies;
    }

    const clickHandlerViewCommentLikesForModal = async (commentId: string) => {
        if (displayCommentLikesForModal) {
            await handleViewCommentLikes(commentId);
            handleClickViewCommentLikes();
        }
    }

    const handleEditComment = async (comment: CommentView) => {
        if (editingComment) {
            setEditingComment(false);
            setUserComment("");
        } else {
            setEditingComment(true);
            setUserComment(comment.content);
            setCommentEditing(comment);
            setMasterCommentForEditing(comment);
            await handleFocusOnComments();
        }
    }

    const handleEditReplyComment = async (comment: CommentView, masterComment: CommentView) => {
        if (editingComment) {
            setEditingComment(false);
            setUserComment("");
        } else {
            setEditingComment(true);
            setUserComment(comment.content);
            setMasterCommentForEditing(masterComment);
            setCommentEditing(comment);
            await handleFocusOnComments();
        }
    }

    const handleDeleteComment = async (comment: CommentView) => {
        const newTrickComments = topLevelTrickComments.filter((c) => c.comment_id !== comment.comment_id);
        setTopLevelTrickComments(newTrickComments);
        if (onSetTrickCommentCount) {
            onSetTrickCommentCount(totalCommentCount - 1);
        }
        await UserService.deleteComment(comment.comment_id, publicId, trickId);
        await reloadTrickComments(trickId, newTrickComments);
    }

    const handleDeleteReplyComment = async (parentCommentId: string, comment: CommentView) => {
        const newCommentToReplyMap = new Map(commentToReplyMap);
        const replies = newCommentToReplyMap.get(parentCommentId);
        const newReplies = replies.filter((reply) => reply.comment_id !== comment.comment_id);
        newCommentToReplyMap.set(parentCommentId, newReplies);
        setCommentToReplyMap(newCommentToReplyMap);
        await UserService.deleteComment(comment.comment_id, publicId, trickId);
    }

    const handleReplyComment = async (comment: CommentView, masterComment: CommentView) => {
        if (replyingComment && commentReplying.comment_id === comment.comment_id) {
            setReplyingComment(false);
            setUserComment("");
        } else if (replyingComment) {
            setUserComment(`@${comment.username} `);
            setCommentReplying(comment);
            setMasterCommentForReplying(masterComment);
            await handleFocusOnComments();
        } else {
            setReplyingComment(true);
            setUserComment(`@${comment.username} `);
            setCommentReplying(comment);
            setMasterCommentForReplying(masterComment);
            await handleFocusOnComments();
        }
    }

    const dismissLikesModal = async (commentId: string) => {
        const modal = modalMap.current.get(commentId);
        if (modal) {
            await modal.current?.dismiss();
        }
    }

    useEffect(() => {
        if (trickId !== "" && trickId !== undefined) {
            getTrickComments(trickId, isAuthed, publicId);
        }
    }, [trickId, isAuthed, publicId]);

    if (displayCommentLikesForModal && viewCommentLikes) {
        return (
            <div className="p-1">
                {
                    <ViewLikesContent
                        trickLikes={commentLikesToView}
                        onClickResult={
                            async (username: string) => {
                                await handleClickViewCommentLikes();
                                onClickPerson(username);
                            }
                        }
                    />
                }
            </div>
        )
    } else {
        return (
            <div className="flex flex-col h-full">
                {isAuthed &&
                    <div className="flex items-end p-2 bg-black border-t border-gray-700">
                        <div className="flex-shrink-0 pr-2">
                            <img
                                className="w-12 h-12 rounded-full"
                                src={state?.user?.profile?.profile_pic ? state?.user?.profile?.profile_pic : '/assets/photos/defaultProPic.png'}
                                alt="User Avatar"
                            />
                        </div>
                        <div className="flex items-end flex-grow border border-gray-700 rounded-lg px-2 bg-gray-800">
                            <IonTextarea
                                className="flex-grow bg-transparent text-white outline-none px-2 resize-none"
                                autoGrow={true}
                                placeholder="Add a comment"
                                rows={1}
                                inputMode="text"
                                value={userComment}
                                ref={userCommentInputRef}
                                onIonInput={(e) => onUserCommentValueChange(e.detail.value)}
                            />
                            {!submitting ?
                                userComment.trim() !== '' && (
                                    <IonButton
                                        color="theme-secondary"
                                        size="small"
                                        onClick={handleCommentSubmit}
                                        className="ml-2 pb-1 flex-shrink-0"
                                    >
                                        <IDXButtonIcon size="text-xl" icon={arrowUpOutline} />
                                    </IonButton>
                                ) :
                                <div className="ml-2 pb-1 flex-shrink-0">
                                    <IonSpinner color="theme-secondary" name="crescent" />
                                </div>
                            }

                        </div>
                    </div>
                }
                {
                    topLevelTrickComments.length > 0 ?
                        (topLevelTrickComments.map((trick_comment, index) => (
                            <div
                                className={`flex flex-col mx-2 gap-x-4 ${index === topLevelTrickComments.length - 1 ? `mb-24` : `mb-4`}`}
                                key={trick_comment.comment_id}>
                                <div
                                    className={`flex flex-row gap-x-4 items-center justify-between mb-2`}
                                    key={index}>
                                    <div className="flex-none" onClick={() => onClickPerson(trick_comment.username)}>
                                        <img className="shrink w-12 h-12 rounded-full"
                                            src={trick_comment.profile_pic ? trick_comment.profile_pic : '/assets/photos/defaultProPic.png'}
                                            alt="User Avatar" />
                                    </div>
                                    <div className="flex flex-col flex-grow">
                                        <div className="flex flex-row justify-between items-center mr-2 gap-x-2">
                                            <div className="flex flex-col">
                                                <div className="flex flex-row items-start justify-between">
                                                    <div className="grow">
                                                        <UsernameDisplay
                                                            username={trick_comment.username}
                                                            className={"text-sm font-bold"}
                                                            onUsernameClickHandler={onClickPerson}
                                                            userId={trick_comment.action_user_id}
                                                            loggedInId={publicId}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    {commentHtmlMap.get(trick_comment.comment_id)}
                                                </div>
                                                <div>
                                                    <div className="flex flex-row gap-x-2">
                                                        {
                                                            ((trick_comment.action_user_id === publicId && !editingComment) || (editingComment && trick_comment.action_user_id === publicId && commentEditing.comment_id !== trick_comment.comment_id)) &&
                                                            <>
                                                                <div className="text-gray-300 text-xs"
                                                                    onClick={() => handleEditComment(trick_comment)}>{"Edit"} </div>
                                                            </>
                                                        }
                                                        {
                                                            trick_comment.action_user_id === publicId && editingComment && commentEditing.comment_id === trick_comment.comment_id &&
                                                            <>
                                                                <div className="text-gray-300 text-xs"
                                                                    onClick={() => handleEditComment(trick_comment)}>{"Cancel"} </div>
                                                            </>
                                                        }
                                                        {
                                                            trick_comment.action_user_id === publicId &&
                                                            <>
                                                                <div className="text-gray-300 text-xs"
                                                                    onClick={() => handleDeleteComment(trick_comment)}>Delete
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="text-gray-300 text-xs"
                                                            onClick={() => handleReplyComment(trick_comment, trick_comment)}>
                                                            {replyingComment && commentReplying.comment_id === trick_comment.comment_id ? "Cancel" : "Reply"}
                                                        </div>
                                                        <div>
                                                            {
                                                                trick_comment.reply_count > 0 && (
                                                                    <div className="text-gray-300 text-xs"
                                                                        onClick={() => handleViewCommentReplies(trick_comment.comment_id)}>
                                                                        {commentToViewReplyMap.has(trick_comment.comment_id) && !commentToViewReplyMap.get(trick_comment.comment_id) ? `- View ${trick_comment.reply_count} replies -` : `- Hide replies -`}
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center justify-items-center flex-none">
                                                <div>
                                                    <ReportComment text={trick_comment.content}
                                                        usernameToReport={trick_comment.username}
                                                        reportingUsername={state?.user?.username}
                                                        idToReport={trick_comment.action_user_id}
                                                        reportingId={state?.user?.id}
                                                        reportedId={trick_comment.action_user_id} />
                                                </div>
                                                <div>
                                                    <IonIcon icon={trick_comment.user_liked ? heart : heartOutline}
                                                        onClick={() => handleLikeComment(trick_comment.comment_id, !trick_comment.user_liked)} />
                                                </div>
                                                {
                                                    trick_comment.like_count > 0 &&
                                                    <div className="text-gray-300"
                                                        onClick={() => clickHandlerViewCommentLikesForModal(trick_comment.comment_id)}
                                                        id={!displayCommentLikesForModal ? "open-modal-" + trick_comment.comment_id : ""}>
                                                        {trick_comment.like_count}
                                                        {
                                                            !displayCommentLikesForModal &&
                                                            <IonModal
                                                                ref={modalMap.current.get(trick_comment.comment_id)}
                                                                trigger={"open-modal-" + trick_comment.comment_id}
                                                                initialBreakpoint={0.75}
                                                                breakpoints={[0.5, .75, 1]}
                                                                onWillPresent={(e) => (handleViewCommentLikes(trick_comment.comment_id))}
                                                                onDidDismiss={(e) => setCommentLikesToView([])}>
                                                                <div className="p-1">
                                                                    {
                                                                        <ViewLikesContent
                                                                            trickLikes={commentLikesToView}
                                                                            onClickResult={
                                                                                async (username: string) => {
                                                                                    await dismissLikesModal(trick_comment.comment_id);
                                                                                    onClickPerson(username);
                                                                                }
                                                                            }
                                                                        />
                                                                    }
                                                                </div>
                                                            </IonModal>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        trick_comment.reply_count > 0 && (
                                            <>
                                                <div>
                                                    {
                                                        commentToViewReplyMap.has(trick_comment.comment_id) && commentToViewReplyMap.get(trick_comment.comment_id) && (
                                                            commentToReplyMap.get(trick_comment.comment_id).map((reply, index) => (
                                                                <div
                                                                    className={`flex flex-row ml-4 gap-x-4 items-center justify-between ${index === commentToReplyMap.get(trick_comment.comment_id).length - 1 ? `mb-2` : `mb-4`}`}
                                                                    key={index}>
                                                                    <div className="flex-none"
                                                                        onClick={() => onClickPerson(reply.username)}>
                                                                        <img className="shrink w-12 h-12 rounded-full"
                                                                            src={reply.profile_pic ? reply.profile_pic : '/assets/photos/defaultProPic.png'}
                                                                            alt="User Avatar" />
                                                                    </div>
                                                                    <div className="flex flex-col flex-grow">
                                                                        <div
                                                                            className="flex flex-row justify-between items-center mr-2 gap-x-2">
                                                                            <div className="flex flex-col">
                                                                                <div
                                                                                    className="flex flex-row items-start justify-between">
                                                                                    <div className="grow">
                                                                                        <UsernameDisplay
                                                                                            username={reply.username}
                                                                                            className={"text-sm font-bold"}
                                                                                            onUsernameClickHandler={onClickPerson}
                                                                                            userId={reply.action_user_id}
                                                                                            loggedInId={publicId}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    {commentHtmlMap.get(reply.comment_id)}
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        <div className="flex flex-row gap-x-2">
                                                                                            {
                                                                                                reply.action_user_id === publicId && !editingComment &&
                                                                                                <>
                                                                                                    <div
                                                                                                        className="text-gray-300 text-xs"
                                                                                                        onClick={() => handleEditReplyComment(reply, trick_comment)}>{"Edit"} </div>
                                                                                                </>
                                                                                            }
                                                                                            {
                                                                                                reply.action_user_id === publicId && editingComment && commentEditing.comment_id === reply.comment_id &&
                                                                                                <>
                                                                                                    <div
                                                                                                        className="text-gray-300 text-xs"
                                                                                                        onClick={() => handleEditReplyComment(reply, trick_comment)}>{"Cancel"} </div>
                                                                                                </>
                                                                                            }
                                                                                            {
                                                                                                reply.action_user_id === publicId &&
                                                                                                <>
                                                                                                    <div
                                                                                                        className="text-gray-300 text-xs"
                                                                                                        onClick={() => handleDeleteReplyComment(trick_comment.comment_id, reply)}>Delete
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                            <div
                                                                                                className="text-gray-300 text-xs"
                                                                                                onClick={() => handleReplyComment(reply, trick_comment)}>
                                                                                                {replyingComment && commentReplying.comment_id === reply.comment_id ? "Cancel" : "Reply"}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className="flex flex-col items-center justify-items-center flex-none">
                                                                                <div>
                                                                                    <ReportComment text={reply.content}
                                                                                        usernameToReport={reply.username}
                                                                                        reportingUsername={state?.user?.username}
                                                                                        idToReport={reply.action_user_id}
                                                                                        reportingId={state?.user?.id}
                                                                                        reportedId={reply.action_user_id} />
                                                                                </div>
                                                                                <div>
                                                                                    <IonIcon
                                                                                        icon={reply.user_liked ? heart : heartOutline}
                                                                                        onClick={() => handleLikeReplyComment(trick_comment.comment_id, reply.comment_id, !reply.user_liked)} />
                                                                                </div>
                                                                                {
                                                                                    reply.like_count > 0 &&
                                                                                    <div className="text-gray-300"
                                                                                        onClick={() => clickHandlerViewCommentLikesForModal(reply.comment_id)}
                                                                                        id={!displayCommentLikesForModal ? "open-modal-" + reply.comment_id : ""}>
                                                                                        {reply.like_count}
                                                                                        {
                                                                                            !displayCommentLikesForModal &&
                                                                                            <IonModal
                                                                                                ref={modalMap.current.get(reply.comment_id)}
                                                                                                trigger={"open-modal-" + reply.comment_id}
                                                                                                initialBreakpoint={0.75}
                                                                                                breakpoints={[0.5, .75, 1]}
                                                                                                onWillPresent={(e) => (handleViewCommentLikes(reply.comment_id))}
                                                                                                onDidDismiss={(e) => setCommentLikesToView([])}>
                                                                                                <div className="p-1">
                                                                                                    {
                                                                                                        <ViewLikesContent
                                                                                                            trickLikes={commentLikesToView}
                                                                                                            onClickResult={
                                                                                                                async (username: string) => {
                                                                                                                    await dismissLikesModal(reply.comment_id);
                                                                                                                    onClickPerson(username);
                                                                                                                }
                                                                                                            }
                                                                                                        />
                                                                                                    }
                                                                                                </div>
                                                                                            </IonModal>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        ))) :
                        !loading && (
                            <div className="flex flex-row ml-2 mr-4 space-x-4 justify-center mb-6 mt-2">
                                <div className="items-center space-x-4">Be the first to drop some yews and cheers!</div>
                            </div>
                        )
                }
            </div>
        );
    }
};

export default CommentDisplay;
