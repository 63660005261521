import React, {useEffect, useState} from "react";
import {IonButton, IonIcon, useIonModal, useIonToast} from "@ionic/react";
import BiskSendModal from "../BiskSendModal/BiskSendModal";
import {OverlayEventDetail} from "@ionic/core/components";
import biskSvg from "../../../assets/icons/bisk/BISK_ICON_NO_DEGREE.svg";
import {useHistory} from "react-router";
import BiskService from "../../../services/bisk.service";
import {alertCircleOutline} from "ionicons/icons";
import {UserBisk} from "../../../models/userBisk";
import {AppContext} from "../../../AppStateProvider";
import {BiskTransaction} from "../../../models/biskTransaction";
import useAuthService from "../../../hooks/useAuthService";
import IDXButtonIcon from "../../ComponentsUI/IDXButtonIcon/IDXButtonIcon";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

interface ContainerProps {
    senderId: string,
    recipientId: string,
    recipientUsername: string,
    isAuthed: boolean,
    trickId?: string,
    trickName?: string,
    component?: string,
}

const BiskHome: React.FC<ContainerProps> = (props: ContainerProps) => {
    const {state, dispatch} = React.useContext(AppContext);
    const {login} = useAuthService();
    const history = useHistory();
    const [bisk, setBisk] = useState<number>(state.bisk);
    const [present] = useIonToast();

    const getBisk = async () => {
        try {
            const bisk: UserBisk = await BiskService.getBiskForUser(props.senderId);
            return bisk.bisk;
        } catch (e) {
            console.log("Error getting Bisk: ", e);
            setBisk(state.bisk);
            return state.bisk;
        }
    }

    useEffect(() => {
        // get Bisk
        if (props.senderId && props.isAuthed) {
            getBisk();
        }
    }, [props.senderId]);

    useEffect(() => {
        setBisk(state.bisk);
    }, [state.bisk]);

    const onBuy = () => {
        history.push("/shop");
    }

    const onSend = async (biskSend: number, note: string) => {
        let typeOfObject = "Video";
        if (props.trickId) {
            typeOfObject = "Video";
        }

        await Haptics.impact({ style: ImpactStyle.Medium });

        // Send Bisk
        try {
            const result: BiskTransaction = await BiskService.sendBiskToUserForObject(props.senderId, props.recipientId, biskSend, props.trickId, typeOfObject, note);
            console.log("Result: ", result);
            await present({
                message: `Successfully sent ${biskSend} Bisk to ${props.recipientUsername} for ${props.trickName}!`,
                duration: 5000, // Duration in milliseconds
                icon: biskSvg,
                position: "top",
                color: "success",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
            const currentBisk = await getBisk();
            setBisk(currentBisk);
            dispatch({type: "setBisk", payload: currentBisk});
        } catch (e) {
            console.log("Error sending Bisk: ", e);
            await present({
                message: `Failed to send Bisk to ${props.recipientUsername}! Error: ${e}`,
                duration: 5000, // Duration in milliseconds
                icon: alertCircleOutline,
                position: "top",
                color: "danger",
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel'
                    }
                ]
            });
        }
    }

    const presentToastLoginHandler = async (message: string) => {
        await present({
            message: message,
            duration: 3000, // Duration in milliseconds
            position: 'top',
            icon: biskSvg,
            buttons: [
                {
                    text: 'Login',
                    handler: async () => {
                        await login();
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel'
                }
            ]
        });
    };

    const [presentBiskModal, dismissBiskModal] = useIonModal(BiskSendModal, {
        onDismiss: (data: string, role: string) => dismissBiskModal(data, role),
        onBuy: onBuy,
        bisk: bisk,
        trickName: props.trickName,
        recipientUsername: props.recipientUsername,
        onSend: onSend,
    });

    async function openBiskModal() {
        await Haptics.impact({ style: ImpactStyle.Light });
        presentBiskModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onBiskClick = async () => {
        if (!props.isAuthed) {
            await presentToastLoginHandler("You must be logged in to send Bisk!");
        } else {
            // Open Modal;
            openBiskModal();
        }
    };

    return (
        props.component === "profile" ?
            <IonButton fill="outline" color="favorite-secondary" size="small" onClick={() => onBiskClick()}>
                <span className="hidden md:inline-flex md:pt-0.5 md:pr-2 md:pl-1 text-white">Bisk</span>
                <IonIcon color="dark" icon={biskSvg}/>
            </IonButton>
            :
            <IonButton fill="clear" size="small" color="dark" onClick={() => onBiskClick()}
                       disabled={props.senderId === props.recipientId}>
                <IDXButtonIcon size="text-2xl" src={biskSvg}/>
            </IonButton>
    );
};

export default BiskHome;
