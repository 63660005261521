import { Browser } from "@capacitor/browser";
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { OverlayEventDetail } from "@ionic/core/components";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSpinner,
    RefresherEventDetail,
    useIonModal,
    useIonToast,
} from "@ionic/react";
import {
    addCircleOutline,
    globeOutline,
    linkOutline,
    pencilOutline,
    personAddOutline,
    personRemoveOutline,
    shareOutline,
} from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import "../global.css";
import BrandProfileSocial from "../components/ComponentsCrewProfile/BrandProfileSocial/BrandProfileSocial";
import CrewRiders from "../components/ComponentsCrew/CrewRiders/CrewRiders";
import EditBrandModal from "../components/ComponentsCrewProfile/EditBrandModal/EditBrandModal";
import LogoutButton from "../components/ComponentsLogin/LogoutButton/LogoutButton";
import PostUpsert from "../components/ComponentsCrewProfile/PostUpsert/PostUpsert";
import ViewProfileFollowInfoButton
    from "../components/ComponentsUI/ViewProfileFollowInfoButton/ViewProfileFollowInfoButton";
import { Brand } from "../models/brand";
import { BrandEvent } from "../models/brandEvent";
import { PictureUploadType } from "../models/pictureUploadType";
import { Post } from "../models/post";
import { SportType } from "../models/sportType";
import { User } from "../models/user";
import { UserProfileSearch } from "../models/user-profile-search";
import { UserTrick } from "../models/user-trick";
import { UserTrickSearch } from "../models/user-trick-search";
import { UserType } from "../models/usertype";
import BrandsService from "../services/brand.service";
import TricksService from "../services/tricks.service";
import UserService from "../services/user.service";
import { analyzeUserTrickResForUnlisted, arrangeTricksByPreferences, handleTagClickUtil, } from "../services/utils";
import { AppContext } from "../AppStateProvider";
import ProfilePicture from "../components/ComponentsProfile/ProfilePicture/ProfilePicture";
import CustomHelmet from "../components/ComponentsFunctional/CustomHelmet/CustomHelmet";
import BrandEventInfoCard from "../components/ComponentsCrewProfile/BrandEventInfoCard/BrandEventInfoCard";
import useAuthService from "../hooks/useAuthService";
import { useAuth } from "../AuthProvider";
import IDXProfileButton from "../components/ComponentsUI/IDXProfileButton/IDXProfileButton";
import ToolbarSearchBarProfile from "../components/ComponentsUI/ToolbarSearchBarProfile/ToolbarSearchBarProfile";
import ProfileVirtualGrid from "../components/ComponentsProfile/ProfileVirtualGrid/ProfileVirtualGrid";
import EventCrewCard from "../components/ComponentsCrew/EventCrewCard/EventCrewCard";
import { useAppState } from "../AppListenerProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingProfileComponent from "../components/ComponentsProfile/LoadingProfileComponent/LoadingProfileComponent";
import IonSpinnerMainContent from "../components/ComponentsUI/IonSpinnerMainContent/IonSpinnerMainContent";
import ProfileVirtualSortableGrid from "../components/ComponentsProfile/ProfileVirtualSortableGrid/ProfileVirtualSortableGrid";
import PostPage from "../components/ComponentsCrew/PostPage/PostPage";
import { set } from "lodash";
import { useBrandProfile } from "../hooks/useBrandProfile";
import EclipticSearchbar from "../components/ComponentsUI/EclipticSearchbar/EclipticSearchbar";

interface CrewParams {
    username?: string;
}

interface CrewPageProps extends RouteComponentProps<CrewParams> { }

const Crew: React.FC<CrewPageProps> = ({ match }) => {
    const history = useHistory();

    const queryClient = useQueryClient();
    const { state, dispatch } = useContext(AppContext);

    const { isNative } = useAppState();
    const { user, isLoading, isAuthenticated } = useAuth();
    const { username } = match.params;

    const { login } = useAuthService();

    const [presentToast] = useIonToast();

    const [brandUrl, setBrandUrl] = useState<string>(undefined);
    const [profileError, setProfileError] = useState<boolean>(false);

    const [followingUser, setFollowingUser] = useState(false);
    const [followingUserLoading, setFollowingUserLoading] = useState(false);

    const [userType, setUserType] = useState<string>(undefined);
    const [loggedInUser, setLoggedInUser] = useState<Brand | User>(undefined);

    const [followersCount, setFollowersCount] = useState<number>(0);
    const [followingCount, setFollowingCount] = useState<number>(0);

    const tabs = ['Events', 'Crew Bag', 'Posts'];
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [clipsLinesEditsLoading, setClipsLinesEditsLoading] = useState(false);

    const {
        updateProfileMutation,
        refreshMutation
    } = useBrandProfile(match.params.username, isAuthenticated, user?.uid);

    // BRAND
    const [editBrand, setEditBrand] = useState<boolean>(false);

    const { data: brand, isLoading: isLoadingBrand, isError: isErrorBrand } = useQuery({
        queryKey: ['brand', username],
        queryFn: () => BrandsService.getBrandByUsername(username),
        enabled: !!username
    });

    // EVENTS
    const { data: events, isLoading: isEventsLoading } = useQuery({
        queryKey: ['brandEvents', brand?.id],
        queryFn: () => BrandsService.getBrandEvents(brand.id),
        enabled: !!brand?.id
    });

    const [eventsFilter, setEventsFilter] = useState<string>("")

    // POSTS
    const { data: posts, isLoading: isPostsLoading } = useQuery({
        queryKey: ['brandPosts', brand?.id],
        queryFn: () => BrandsService.getBrandPosts(brand.id),
        enabled: !!brand?.id
    });

    // Post Modal
    // Post mutation for refreshing posts after edit
    const refreshPostsMutation = useMutation({
        mutationFn: (brandId: string) => BrandsService.getBrandPosts(brandId),
        onSuccess: (updatedPosts) => {
            queryClient.setQueryData(['brandPosts', brand?.id], updatedPosts);
        }
    });

    // posts data
    const [currPostEditing, setCurrentPostEditing] = useState<Post>(undefined);

    // Modal setup
    const [presentEditPost, dismissPost] = useIonModal(PostUpsert, {
        onDismiss: async (data: string | null | undefined | number, role?: string) => {
            dismissPost(data, role);
            if (role === 'success') {
                await refreshPostsMutation.mutateAsync(brand.id);
            }
        },
        brand: brand,
        post: currPostEditing,
    });

    // Function to handle opening the edit modal
    const handleEditPost = (post: Post) => {
        setCurrentPostEditing(post);
        presentEditPost({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                setCurrentPostEditing(undefined);
            }
        });
    };

    // BAG OF TRICKS
    const { data: userTricks } = useQuery({
        queryKey: ['brandTricks', brand?.id],
        queryFn: async () => {
            const allUserTricks: UserTrick[] = [];
            for (const rider of brand.user_interactions.members) {
                const userTricksRes = await TricksService.getUserTricks(rider);
                allUserTricks.push(...userTricksRes);
            }

            const preferences = await UserService.getUserTrickPreferences(brand.id);
            const userTricksToDisplay = analyzeUserTrickResForUnlisted(
                allUserTricks,
                brand.auth_id === user.uid
            );

            if (preferences) {
                const preferencesMap = new Map<string, number>(Object.entries(preferences));
                return preferencesMap.size > 0
                    ? arrangeTricksByPreferences(userTricksToDisplay, preferencesMap)
                    : userTricksToDisplay;
            }

            return userTricksToDisplay;
        },
        enabled: !!brand?.id
    });

    const updateTrickPreferencesMutation = useMutation({
        mutationFn: (sortableTricks: UserTrick[]) => {
            const trickIds = sortableTricks.map(trick => trick.trick_id);
            const positions = Array.from(sortableTricks.entries()).map(([index]) => index);
            return UserService.upsertUserTrickPreferences(user.uid, trickIds, positions);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['brandTricks', brand?.id] });
        }
    });

    // team bag of tricks
    const [bagOfTricksFilter, setBagOfTricksFilter] = useState<string>("")
    const [editSortingBrandTricks, setEditSortingBrandTricks] = useState(false);


    const [presentFollowing, dismissFollowing] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowing(data, role),
        userProfileId: brand?.id,
        followers: false,
    })

    const [presentFollowers, dismissFollowers] = useIonModal(ViewProfileFollowInfoButton, {
        onDismiss: (data: string, role: string) => dismissFollowers(data, role),
        userProfileId: brand?.id,
        followers: true,
    })

    function openFollowersModal() {
        presentFollowers({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onFollowersProfileClick = async () => {
        // Open Modal;
        openFollowersModal();
    };

    function openFollowingModal() {
        presentFollowing({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onFollowingProfileClick = async () => {
        // Open Modal;
        openFollowingModal();
    };

    // members
    const onClickSubmitTeamRiders = async (user_ids: string[]) => {
        console.log(user_ids);
        const updatedBrand = await BrandsService.upsertRidersToBrand(user.uid, user_ids);
        await refreshMutation.mutateAsync();
    }

    const [presentTeamRiders, dismissTeamRiders] = useIonModal(CrewRiders, {
        onDismiss: (data: string, role: string) => dismissTeamRiders(data, role),
        userProfile: { ...brand },
        editBrand: editBrand,
        onClickSubmitTeamRiders: onClickSubmitTeamRiders
    })

    function openRidersModal() {
        presentTeamRiders({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onClickBrandMembers = async () => {
        openRidersModal();
    }

    // GET PROFILE
    const resetProfile = async () => {
        console.log("resetting userprofile");
        setEditSortingBrandTricks(false);

        setProfileError(false);
        setFollowingUser(false);
        setFollowingUserLoading(false);

        setEditBrand(undefined);
        setBrandUrl(undefined);
        setBagOfTricksFilter("");
        setEventsFilter("");

        setFollowersCount(0);
        setFollowingCount(0);

        setClipsLinesEditsLoading(false);
    }

    const checkIfFollowingUser = async (loggedInUserId: string, brandId: string) => {
        const isFollowing = await UserService.checkIfUserFollowingOtherUser(loggedInUserId, brandId);
        setFollowingUser(isFollowing);
    }

    const getBrandProfile = async () => {
        await resetProfile();

        setClipsLinesEditsLoading(true);

        console.log("Getting brand with this username: " + brand.username);
        setBrandUrl(`${process.env.REACT_APP_IDX_URL}/crews/${username}`);

        const followersCountRes = await UserService.getFollowCount(brand.id, true);
        const followingCountRes = await UserService.getFollowCount(brand.id, false);
        setFollowersCount(followersCountRes);
        setFollowingCount(followingCountRes);

        if (!isAuthenticated) {
            setEditBrand(false);
        } else {
            if (brand.auth_id === user.uid) {
                setEditBrand(true);
            }

            const userType = await UserService.getUserType(user.uid)
            setUserType(userType);

            if (userType === UserType.USER) {
                const loggedInUserRes: User = await UserService.getUserByAuthId(user.uid);
                setLoggedInUser(loggedInUserRes);
                await checkIfFollowingUser(loggedInUserRes.id, brand.id);
            }

            if (userType === UserType.BRAND) {
                const loggedInUserRes: Brand = await BrandsService.getBrandByAuthId(user.uid);
                setLoggedInUser(loggedInUserRes);
                await checkIfFollowingUser(loggedInUserRes.id, brand.id);
            }
        }

        window.prerenderReady = true;

        setClipsLinesEditsLoading(false);
    }

    useEffect(() => {
        if (!isLoading && isAuthenticated && username) {
            console.log('match.params:', match.params);
            if (!username && state.user.username) {
                console.log("No username in URL, but user is logged in");
            } else if (username) {
                if (brand && !isLoadingBrand) {
                    getBrandProfile();
                } else if (isErrorBrand) {
                    setProfileError(true);
                }
            }
        }
    }, [isLoading, user?.uid, username, isLoadingBrand, isErrorBrand, brand, state?.user?.username, isAuthenticated]);

    // Edit Brand Logic
    const [presentEditProfileModal, dismissEditProfileModal] = useIonModal(EditBrandModal, {
        onDismiss: (data: string, role: string) => dismissEditProfileModal(data, role),
        onEditProfileSubmit: async (
            name: string,
            username: string,
            homeMountain: string,
            sports: string[],
            trickInterests: string[],
            instagram: string,
            tiktok: string,
            youtube: string,
            website: string,
            website_label: string,
            discord: string,
            twitter: string,
            brand_description: string,
            contact: string,
            setup: string
        ) => {
            try {
                await updateProfileMutation.mutateAsync({
                    name,
                    username,
                    homeMountain,
                    sports,
                    trickInterests,
                    instagram,
                    tiktok,
                    youtube,
                    website,
                    website_label,
                    discord,
                    twitter,
                    brand_description,
                    contact,
                    setup
                });
                dismissEditProfileModal(null, 'success');
            } catch (error) {

            }
        },
        userProfileEdit: { ...brand },
        isLoading: updateProfileMutation.isPending,
        isNative
    });

    function openEditProfileModal() {
        presentEditProfileModal({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    // Social Modal
    const [presentSocial, dismissSocial] = useIonModal(BrandProfileSocial, {
        onDismiss: (data: string, role: string) => dismissSocial(data, role),
        userProfileSocial: { ...brand }
    });

    function openSocialModal() {
        presentSocial({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        });
    }

    const onSocialProfileClick = async () => {
        // Open Modal;
        openSocialModal();
    };

    // Share Brand Logic
    const writeProfileUrlToClipboard = async () => {
        if (isNative) {
            let shareOptions: any = {
                title: "Ecliptic // " + brand?.username,
                dialogTitle: "Ecliptic // " + brand?.username,
                url: brandUrl,
            }
            await Share.share(shareOptions)
        } else {
            await Clipboard.write({
                url: brandUrl
            })
            await presentToast({
                header: 'Link Copied',
                position: 'top',
                message: 'You can now share your profile link with friends',
                duration: 2000,
                buttons: [
                    {
                        text: 'OK',
                        role: 'cancel',
                    }
                ]
            });
        }
    }

    const onFollowUser = async (followingUserState: boolean) => {
        if (isAuthenticated) {
            setFollowingUserLoading(true);

            const response: User = await UserService.followUserV2(loggedInUser.id, brand.id, !followingUserState);
            if (response) {
                setFollowingUserLoading(false);
                setFollowingUser(!followingUserState);

                const followersCountRes = await UserService.getFollowCount(brand.id, true);
                const followingCountRes = await UserService.getFollowCount(brand.id, false);
                setFollowersCount(followersCountRes);
                setFollowingCount(followingCountRes);

                const followCountForLoggedInUser = await UserService.getFollowCount(loggedInUser.id, false);
                dispatch({ type: 'setFollowing', payload: followCountForLoggedInUser });
            }
        } else {
            await presentToast({
                header: 'Login Required',
                message: 'You must be logged in to follow a user',
                position: 'top',
                buttons: [
                    'Cancel',
                    {
                        text: 'Login',
                        handler: async () => {
                            await login();
                        }
                    }
                ]
            });
        }
    }

    const onResetBrand = async (event: CustomEvent<RefresherEventDetail>) => {
        try {
            await refreshMutation.mutateAsync();
        } finally {
            event.detail.complete();
        }
    }

    const onTrickSelection = (trick: UserTrick): void => {
        history.push('/clip/' + trick.user_id + '/' + trick.trick_id);
        return;
    }

    const goToAddEventPost = () => {
        history.push(`/crew-upload`);
        return;
    }

    const goToLink = async (url: string) => {
        if (isNative) {
            await Browser.open({ url: url, presentationStyle: 'popover', 'toolbarColor': '#000000' });
        } else {
            window.open(url, '_blank');
        }
    }

    const goToBioLink = (link: string) => {
        goToLink(link);
    }

    const goToEventDetail = (event: BrandEvent) => {
        history.push(`/event/${event.id}`);
        return;
    }

    const getColumnCountEvents = () => {
        const width = window.innerWidth;
        if (width >= 1280) return 2;
        if (width >= 768) return 2;
        if (width >= 640) return 1;
        return 1;
    };

    if (isLoading || isEventsLoading || isPostsLoading || clipsLinesEditsLoading || (isAuthenticated && !brand && !profileError)) {
        return (
            <IonPage>
                <CustomHelmet
                    title={brand?.username ? "Ecliptic // " + brand?.username : "Ecliptic // View Profile"}
                    description={brand?.username ? `View ${brand?.username}'s Bag of Tricks` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                    image={brand?.profile?.profile_pic ? brand?.profile?.profile_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                    url={`https://ecliptic.day/profile/${username}`} />
                <ToolbarSearchBarProfile username={username} />
                <IonContent>
                    <LoadingProfileComponent />
                </IonContent>
            </IonPage>
        );
    }

    return (!profileError ?
        <IonPage>
            <CustomHelmet title={brand?.username ? "Ecliptic // " + brand?.username : "Ecliptic // View Crew}"}
                description={brand?.name ? `View ${brand?.name}'s Crew` : "Explore Ecliptic and Follow Skiers and Snowboarders To Create Your Own Feed"}
                image={brand?.profile?.cover_pic ? brand.profile.cover_pic : brand?.profile?.profile_pic ? brand.profile.profile_pic : "https://mctwist.blob.core.windows.net/logos/INFD_NEW_BLACK_PNG_ICON.png"}
                url={`https://ecliptic.day/crews/${username}`} />
            <ToolbarSearchBarProfile username={username} />
            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={onResetBrand}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <div className="flex flex-col mx-2 mt-2 mb-2 gap-3 font-bold text-2xl">
                    <div className="flex flex-row gap-4 items-center">
                        {/* Box 1: Pic + Name + Sport + Location */}
                        <div>
                            <ProfilePicture src={brand?.profile?.profile_pic} editProfile={editBrand}
                                pictureUploadType={PictureUploadType.PROFILE}
                                isNative={isNative}></ProfilePicture>
                        </div>
                        <div className="flex flex-col flex-grow">
                            <div className="flex flex-row">
                                <div className="flex-auto">{brand?.username}</div>
                            </div>
                            {/* TODO: max character length is 15 characters */}
                            <div className="flex flex-row gap-2 font-normal text-base">
                                <div>{brand?.name}</div>
                            </div>
                            <div className="flex flex-row gap-2 font-normal text-base">
                                <div>{brand?.profile?.home_mountain}</div>
                            </div>
                            {brand?.profile?.sports ? <div className="flex flex-row gap-2 font-normal text-base">
                                {brand.profile.sports.includes(SportType.SNOWBOARDING) ? <div>🏂</div> : null}
                                {brand.profile.sports.includes(SportType.SKIING) ? <div>⛷️</div> : null}
                            </div> : null}
                            {
                                brand?.profile?.website && brand?.profile?.website !== "" &&
                                <div className="flex flex-row items-center gap-x-1">
                                    <IonIcon icon={linkOutline} size="small"></IonIcon>
                                    <div className="flex flex-row gap-2 font-normal text-base"
                                        onClick={() => goToBioLink(brand.profile.website)}>
                                        {brand.profile?.website_name && brand.profile?.website_name !== "" ? brand.profile.website_name : brand.profile.website}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="flex flex-col gap-0.5 items-end">
                            <div className="flex">
                                {editBrand ?
                                    <IDXProfileButton icon={pencilOutline} spanText={"Edit"}
                                        onClick={() => openEditProfileModal()} fill={"solid"}
                                        color={"favorite"} />
                                    :
                                    followingUserLoading ? <IonSpinner name="dots"></IonSpinner> :
                                        (!followingUser || !isAuthenticated ?
                                            <IDXProfileButton onClick={() => onFollowUser(followingUser)}
                                                icon={personAddOutline}
                                                spanText={"Follow"} fill="solid" color="favorite" /> :
                                            <IDXProfileButton onClick={() => onFollowUser(followingUser)}
                                                icon={personRemoveOutline}
                                                spanText={"Unfollow"} fill="solid" color="favorite" />)
                                }
                            </div>
                            <div className="flex">
                                <IDXProfileButton icon={globeOutline} spanText={'Info'}
                                    onClick={onSocialProfileClick} />
                            </div>
                            <div className="flex">
                                <IDXProfileButton icon={shareOutline} spanText={'Share'}
                                    onClick={writeProfileUrlToClipboard} />
                            </div>
                            {editBrand ?
                                <IDXProfileButton icon={addCircleOutline} fill={"solid"} color={"favorite"}
                                    spanText={'Add Event/Post'}
                                    onClick={() => goToAddEventPost()} /> : null}
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <IonButton fill="solid" size="small" color="favorite"
                            onClick={() => onFollowersProfileClick()}>
                            Followers: {followersCount}
                        </IonButton>
                        <IonButton fill="solid" size="small" color="favorite"
                            onClick={() => onFollowingProfileClick()}>
                            Following: {followingCount}
                        </IonButton>
                        <IonButton fill="solid" size="small" color="favorite" onClick={() => onClickBrandMembers()}>
                            Riders: {brand?.user_interactions?.members ? (brand?.user_interactions?.members.length === 0 ? 0 : brand?.user_interactions?.members?.length) : 0}
                        </IonButton>
                    </div>
                </div>
                <div className="flex justify-center space-x-4 border-b-2 border-gray-500 text-base font-bold">
                    {tabs.map((tab, index) => (
                        <div key={index} className={activeTab === tab ? 'border-b-4 border-[#5963A7]' : ''}>
                            <button
                                key={tab}
                                className={`py-2 px-2.5 text-xl ${activeTab === tab ? 'border-b-4 border-[#5963A7] text-white' : 'text-gray-500 hover:text-white'}`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab} {!clipsLinesEditsLoading && <span
                                    className="text-sm">{tab === 'Events' ? ` ${events?.length}` : tab === 'Posts' ? ` ${posts?.length}` : tab === 'Crew Bag' ? ` ${userTricks?.length}` : ''}</span>}
                            </button>
                        </div>

                    ))}
                </div>
                <div className="p-1">
                    <div>
                        {activeTab === "Events" ?
                            <>
                                <div className="flex flex-row justify-between items-center mb-0.5">
                                    <EclipticSearchbar onIonInput={(e) => setEventsFilter(e.detail.value)}
                                        showClearButton="focus"
                                        showCancelButton="focus"
                                        style={{ paddingTop: 0, paddingBottom: 0 }}
                                        id="searchText"
                                        debounce={0}
                                        animated={true}></EclipticSearchbar>
                                </div>
                                {
                                    !isEventsLoading ?
                                        <>
                                            <ProfileVirtualGrid<BrandEvent>
                                                items={events}
                                                averageHeight={1000}
                                                renderItem={
                                                    (event) =>
                                                        <div>
                                                            <EventCrewCard event={event}></EventCrewCard>
                                                        </div>
                                                }
                                                getItemId={(event) => event.id}
                                                filter={(event: BrandEvent) =>
                                                    event.name.toLowerCase().includes(eventsFilter.toLowerCase())
                                                }
                                                onClick={goToEventDetail}
                                                activeTab={activeTab}
                                                getColumnCount={getColumnCountEvents}
                                            />
                                        </> :
                                        <div
                                            className="flex flex-col gap-2 m-4 space-y-4 justify-center items-center">
                                            <IonSpinnerMainContent className={"my-2"}
                                                size="medium" /> {/* You can choose other spinner names as well */}
                                        </div>
                                }
                            </> : null
                        }
                    </div>
                    <div>
                        <div>
                            {activeTab === "Crew Bag" ?
                                <>
                                    <div className="flex flex-row justify-between items-center mb-0.5">
                                        <EclipticSearchbar onIonInput={(e) => setBagOfTricksFilter(e.detail.value)}
                                            showClearButton="focus"
                                            showCancelButton="focus"
                                            style={{ paddingTop: 0, paddingBottom: 0 }}
                                            id="searchText"
                                            debounce={0}
                                            animated={true}></EclipticSearchbar>
                                    </div>
                                    <div>
                                        <ProfileVirtualSortableGrid
                                            items={userTricks}
                                            setItems={updateTrickPreferencesMutation.mutateAsync}
                                            renderItem={
                                                (trick) =>
                                                    <div key={trick.trick_id} className={trick.tags.includes("Unlisted") ? "opacity-25" : ""}>
                                                        <BrandEventInfoCard trick={trick as UserTrickSearch} />
                                                    </div>
                                            }
                                            getItemId={(trick) => trick.trick_id}
                                            filter={(trick: UserTrick) =>
                                                trick.trick_name.toLowerCase().includes(bagOfTricksFilter.toLowerCase()) ||
                                                (trick.location && trick.location.toLowerCase().includes(bagOfTricksFilter.toLowerCase()))
                                            }
                                            onClick={onTrickSelection}
                                            activeTab={activeTab}
                                            isNative={isNative}
                                            disabled={isNative ? (!editSortingBrandTricks || bagOfTricksFilter.length > 0 || !editBrand) : (bagOfTricksFilter.length > 0 || !editBrand)}
                                        />
                                    </div>
                                </> : null}
                        </div>
                    </div>
                    <div>
                        {
                            activeTab === "Posts" ?
                                <ProfileVirtualGrid
                                    getItemId={(post: Post) => post.id}
                                    filter={(post: Post) => post.title.toLowerCase().includes(eventsFilter.toLowerCase())}

                                    items={posts}
                                    averageHeight={600}
                                    getColumnCount={getColumnCountEvents}
                                    renderItem={(post: Post) => <PostPage post={post} goToLink={goToLink} editBrand={editBrand} brand={brand} />}
                                    activeTab={""} /> : null
                        }
                    </div>
                </div>
                <div className="flex flex-row justify-center">
                    <LogoutButton
                        display={editBrand}></LogoutButton> {/* TODO: get rid of when figure out how to only login once*/}
                </div>
            </IonContent>
        </IonPage> :
        <IonPage>
            <IonContent>
                <div className="flex flex-col gap-2 m-4 space-y-4 font-bold text-2xl">
                    <div className="flex-col text-center">
                        <div>There was an error loading this crew.</div>
                    </div>
                    <div className="flex-col text-center">
                        <IonButton routerLink="/home" color="favorite">Go Back to Ecliptic</IonButton>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Crew;
