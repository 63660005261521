// VolumeContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { VolumeButtons, VolumeButtonsOptions, VolumeButtonsResult } from '@capacitor-community/volume-buttons';
import { Capacitor } from '@capacitor/core';
import PreferencesService from './services/preferences.service';
import { AppContext } from './AppStateProvider';
import { Volumes } from '@ottimis/capacitor-volumes';

interface VolumeContextType {
    isMuted: boolean;
    setMuted: (muted: boolean) => Promise<void>;
}

const VolumeContext = createContext<VolumeContextType | undefined>(undefined);

export const VolumeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isMuted, setLocalMuted] = useState(true);
    const isNative = Capacitor.isNativePlatform();
    const { state, dispatch } = useContext(AppContext);

    const getMuted = async () => {
        try {
            const muted = await PreferencesService.getMutedPreferences();
            if (muted) {
                const shouldBeMuted = muted === 'true';
                setLocalMuted(shouldBeMuted);
            } else {
                setLocalMuted(true);
                await PreferencesService.setMutedPreferences('true');
                dispatch({ type: 'setMutedSettings', payload: true });
            }
        } catch (e) {
            console.log(e);
            setLocalMuted(true);
            await PreferencesService.setMutedPreferences('true');
            dispatch({ type: 'setMutedSettings', payload: true });
        }
    };

    // Initialize muted state from preferences
    useEffect(() => {
        if (isNative) {
            getMuted();
        }
    }, [isNative, state?.mutedSettings]);

    // Set up volume button listeners
    useEffect(() => {
        if (!isNative) return;

        const setupVolumeListener = async () => {
            const options: VolumeButtonsOptions = {};

            if (Capacitor.getPlatform() === 'ios') {
                options.disableSystemVolumeHandler = false;
            } else if (Capacitor.getPlatform() === 'android') {
                options.suppressVolumeIndicator = false;
            }

            const callback = async (result: VolumeButtonsResult) => {
                if (result.direction === 'up') {
                    await setMuted(false);
                } else {
                    const { value } = await Volumes.getVolumeLevel();
                    if (value === 0) {
                        await setMuted(true);
                    }
                }
            };

            try {
                await VolumeButtons.watchVolume(options, callback);
            } catch (error) {
                console.error('Error setting up volume buttons:', error);
            }
        };

        setupVolumeListener();

        return () => {
            if (isNative) {
                VolumeButtons.clearWatch().catch(console.error);
            }
        };
    }, [isNative]);

    const setMuted = async (newMutedState: boolean) => {
        setLocalMuted(newMutedState);
        await PreferencesService.setMutedPreferences(newMutedState ? 'true' : 'false');
        dispatch({ type: 'setMutedSettings', payload: newMutedState });
    };

    return (
        <VolumeContext.Provider value={{ isMuted, setMuted }}>
            {children}
        </VolumeContext.Provider>
    );
};

export const useVolume = () => {
    const context = useContext(VolumeContext);
    if (context === undefined) {
        throw new Error('useVolume must be used within a VolumeProvider');
    }
    return context;
};